import { useState } from 'react';
import PropTypes from 'prop-types';
import { useCancelPromise } from 'services/hooks/useCancelPromise';

export default function useImageChangeConfirm({
  onClose,
  isLoading,
  setIsLoading,
  setIsEditing,
  removePicture,
  isPictureDeleted,
  setIsPictureDeleted,
  profilePictureBlobId
}) {
  const [shouldConfirm, setShouldConfirm] = useState(false);
  const [error, setError] = useState(null);
  const cancelPromise = useCancelPromise();
  function handleClose(forceClose = false) {
    if (isLoading && !forceClose) {
      return;
    }
    onClose();
    setShouldConfirm(false);
    setIsEditing(false);
    setIsPictureDeleted(false);
  }
  function handleRemovePhoto() {
    setError(null);
    setIsLoading(true);
    setIsPictureDeleted(false);
    removePicture(profilePictureBlobId)
    .then(() => {
      if (!cancelPromise.current) {
        setIsLoading(false);
        setIsPictureDeleted(true);
        handleClose(true);
      }
    })
    .catch(err => {
      setError(err);
      setIsPictureDeleted(false);
      setIsLoading(false);
    });
  }
  function handleChangeClick() {
    setIsEditing(true);
    setIsPictureDeleted(false);
  }
  return {
    shouldConfirm,
    handleClose,
    setShouldConfirm,
    handleRemovePhoto,
    handleChangeClick,
    error
  };
}

useImageChangeConfirm.propTypes = {
  onClose: PropTypes.func,
  isLoading: PropTypes.bool,
  setIsLoading: PropTypes.func,
  setIsEditing: PropTypes.func,
  removePicture: PropTypes.func
};
