import { useState, useEffect, useCallback, useMemo, useLayoutEffect } from 'react';
import { useCancelPromise } from './useCancelPromise';
import { UnsavedChanges } from 'services/unsavedChanges';
import shortid from 'shortid';

export function useInputState({
  initialValue = '',
  initialMaskedValue = '',
  validations = [
    {
      validate: () => true,
      errorMessage: '',
      returnsPromise: false
    }
  ],
  checkIsChanged,
  confirmAbandonUnsavedChanges = false,
  unsavedChangesGlobalKey
}) {
  const [fieldId] = useState(shortid.generate());
  const [value, setValue] = useState(initialValue);
  const [maskedValue, setMaskedValue] = useState(initialMaskedValue);
  const [error, setError] = useState(null);
  const [isValid, setIsValid] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const cancelPromise = useCancelPromise();
  const unsavedChangesInstance = UnsavedChanges.getInstance();

  const isChanged = useMemo(() => {
    if (checkIsChanged) {
      return checkIsChanged(initialValue, value);
    } else {
      if (initialValue !== value) {
        return true;
      } else {
        return false;
      }
    }
  }, [initialValue, value]);

  useLayoutEffect(() => {
    if (confirmAbandonUnsavedChanges && unsavedChangesGlobalKey) {
      if (isChanged && !unsavedChangesInstance.hasUnsavedChange(unsavedChangesGlobalKey, fieldId)) {
        unsavedChangesInstance.setUnsavedChange(unsavedChangesGlobalKey, fieldId);

        return () => {
          unsavedChangesInstance.removeUnsavedChange(unsavedChangesGlobalKey, fieldId);
        };
      } else if (!isChanged && unsavedChangesInstance.hasUnsavedChange(unsavedChangesGlobalKey, fieldId)) {
        unsavedChangesInstance.removeUnsavedChange(unsavedChangesGlobalKey, fieldId);
      }
    }
  }, [confirmAbandonUnsavedChanges, isChanged, unsavedChangesGlobalKey, fieldId]);

  useEffect(() => {
    setError(null);
    setIsValid(true);
  }, [value]);

  useEffect(() => {
    if (error !== null) {
      setIsValid(false);
    } else {
      setIsValid(true);
    }
  }, [error]);

  const validateValue = useCallback(async () => {
    if (!cancelPromise.current) {
      setIsLoading(true);
    }

    for (let i = 0; i < validations.length; i++) {
      const {
        validate = () => true,
        errorMessage = '',
        returnsPromise = false
      } = validations[i] || {};

      if (returnsPromise) {
        const isValueValid = await validate(value).catch(promiseError => {
          if (!cancelPromise.current) {
            setIsLoading(false);
            setError(errorMessage || promiseError);
          }

          return false;
        });

        if (isValueValid === false) {
          return false;
        }
      } else if (!validate(value)) {
        setError(errorMessage);
        setIsLoading(false);
        return false;
      }
    }

    if (!cancelPromise.current) {
      setIsLoading(false);
      setError(null);
      setIsValid(true);
    }
    return true;
  }, [value, validations, cancelPromise]);

  const resetValue = useCallback(() => {
    setValue(initialValue);
    setMaskedValue(initialMaskedValue);
  }, [initialMaskedValue, initialValue]);

  return {
    value,
    setValue,
    maskedValue,
    setMaskedValue,
    isValid,
    validateValue,
    error,
    setError,
    isLoading,
    setIsLoading,
    resetValue,
    isChanged
  };
}
