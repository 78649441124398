import { useEffect, useRef } from 'react';

export function useCancelPromise() {
  const cancelPromise = useRef(false);

  useEffect(() => {
    return () => {
      cancelPromise.current = true;
    };
  }, []);

  return cancelPromise;
}
