import { useInputState } from './useInputState';

const validations = [
  {
    validate: value => value && value.trim && !!value.trim(),
    errorMessage: 'dateOfBirth.yearError'
  },
  {
    validate: value => value <= new Date().getFullYear() && value >= 1900,
    errorMessage: 'dateOfBirth.yearError'
  }
];

export function useBirthYearInputState(options) {
  return useInputState({
    initialValue: null,
    validations,
    ...(options || {})
  });
}
