import { useState } from "react";
import { AMOUNT_LIMIT, MAXIMUM_CHARACTER_COUNT } from "../services/constants";

export const useGiftDetailsError = () => {
  const [amountError, setAmountError] = useState({});
  const [messageError, setMessageError] = useState({});

  // Validation logic for Gift Checkout form
  const validateMessage = (input) => input?.length <= MAXIMUM_CHARACTER_COUNT;
  const validateAmountNotEmpty = (amount) => amount !== 0;
  const validateMinimumAmount = (amount) => amount >= AMOUNT_LIMIT.MINIMUM;
  const validateMaximumAmount = (amount) => amount <= AMOUNT_LIMIT.MAXIMUM;

  // Validates all current values on form
  const validateDetailsForm = (amount, message) => {
    const isAmountValueValid = validateAmountNotEmpty(amount);
    const isAmountMaximumValid = validateMaximumAmount(amount);
    const isAmountMinimumValid = validateMinimumAmount(amount);
    const isMessageValid = validateMessage(message);

    return {
      isAmountValid: isAmountMaximumValid && isAmountMinimumValid && isAmountValueValid,
      isMessageValid,
      isAmountValueValid,
      isAmountMinimumValid,
      isAmountMaximumValid
    };
  };

  // Error updating functions
  const updateAmountError = (amount) => {
    if (!validateAmountNotEmpty(amount)) {
      setAmountError({
        severity: 'error',
        message: 'giftDetails.error.amountEmpty'
      });
    } else if (!validateMinimumAmount(amount)) {
      setAmountError({
        severity: 'error',
        message: 'giftDetails.error.amountUnderMin'
      });
    } else if (!validateMaximumAmount(amount)) {
      setAmountError({
        severity: 'error',
        message: 'giftDetails.error.amountOverMax'
      });
    } else {
      setAmountError({});
    }
  };

  const updateMessageError = (input) => {
    if (!validateMessage(input)) {
      setMessageError({
        severity: 'error',
        message: 'giftDetails.error.exceededMaximumCharacter'
      });
    } else {
      setMessageError({});
    }
  };

  return {
    amountError,
    messageError,
    setMessageError,
    setAmountError,
    validateMessage,
    validateMaximumAmount,
    validateMinimumAmount,
    validateAmountNotEmpty,
    validateDetailsForm,
    updateAmountError,
    updateMessageError
  };
};
