export function getQueryParameter(param) {
  return new URLSearchParams(window.location.search).get(param) || '';
}

export function getAllQueryParameters() {
  const params = {};
  for (const [key, value] of new URLSearchParams(window.location.search).entries()) {
    params[key] = value;
  };
  return params;
}

export function addQueryParameter(path, param) {
  const { key, value } = param;
  const currentParams = new URLSearchParams(window.location.search);
  currentParams.set(key, value);
  window.history.replaceState("", "", `${path}?${currentParams.toString() || ''}`);
}

export function removeQueryParameters(path, paramList) {
  paramList.forEach((param) => {
    const currentParams = new URLSearchParams(window.location.search);
    if (currentParams.get(param)) {
      currentParams.delete(param);
    }
    window.history.replaceState("", "", `${path}?${currentParams.toString() || ''}`);
  });
}
