import { useInputState } from './useInputState';
import { validateSIN } from 'services/sin';

export function useSINInputState(options) {
  const { validations, ...otherOptions } = options || {};
  return useInputState({
    initialValue: '',
    validations: [
      {
        validate: value => value && value.trim && !!value.trim(),
        errorMessage: 'SINRequiredError'
      },
      {
        validate: value => validateSIN(value.trim()),
        errorMessage: 'SINFormatError'
      },
      ...(validations || [])
    ],
    ...(otherOptions || {})
  });
}
