import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/common/Button';
import OldImage from 'assets/icons/old_image.svg';
import Person from 'assets/icons/person.svg';
import useImageDrop from './hooks/useImageDrop';
import Alert from 'components/common/Alert';
import './styles/imageDrop.scss';

export default function ImageDrop({
  localized,
  setFile,
  onClose
}) {
  const { alertProps, getRootProps, getInputProps, open } = useImageDrop({ setFile });
  const { severity, message } = alertProps || {};

  return (
    <div className='image-drop'>
      <div className='image-drop__img'>
        <img src={OldImage} alt={localized['imageDrop.dropImage']} />
      </div>
      <div className='image-drop__title'>
        {localized['imageDrop.title']}
      </div>
      {message && (
        <div className="image-drop__header">
          <Alert
            severity={severity}
            showBorder
            className="image-drop__header__error"
          >
            {localized[message]}
          </Alert>
        </div>
      )}
      <div className='image-drop__upload' {...getRootProps({ onClick: e => e.stopPropagation() })}>
        <div className='image-drop__upload__person-icon'>
          <img src={Person} alt={localized['imageDrop.dropAreaIcon']} />
        </div>
        <div className='image-drop__upload__description'>
          <input {...getInputProps()} />
          <p className='image-drop__upload__description__drag-drop'>
            {localized['imageDrop.dragAndDrop']}
            <span type='button' className='image-drop__upload__description__browse' onClick={open}>
              {localized['imageDrop.browse']}
            </span>
          </p>
          <p className='image-drop__upload__description__format'>
            {localized['imageDrop.avatarFormat']}
          </p>
          <p className='image-drop__upload__description__size'>
            <span className='image-drop__upload__description__size__size1'>
              {localized['imageDrop.avatarSize1']}
            </span>
            <span className='image-drop__upload__description__size__size2'>
              {localized['imageDrop.avatarSize2']}
            </span>
          </p>
        </div>
      </div>
      <div className='image-drop__btn'>
        <Button className='large' variant='text' onClick={onClose}>
          {localized['cancel']}
        </Button>
      </div>
    </div>
  );
};

ImageDrop.propTypes = {
  localized: PropTypes.object,
  setFile: PropTypes.func,
  onClose: PropTypes.func
};
