export function validateSIN(sin = '') {
  const condition = /^([0-9]{9})$/;
  const is9Digits = condition.test(sin);
  const notStartWith0 = Number(sin.charAt(0)) !== 0;
  const notStartWith8 = Number(sin.charAt(0)) !== 8;
  if (is9Digits && notStartWith0 && notStartWith8) {
    let sum = 0;
    let iDigit = 0;
    const arr = sin.split('').map(x => parseInt(x, 10));
    for (let i = 0; i < arr.length; i++) {
      if (i % 2 !== 0) {
        iDigit = arr[i] * 2;
        sum += iDigit < 10 ? iDigit : iDigit - 9;
      } else {
        sum += arr[i];
      }
    }
    return sum % 10 === 0;
  } else {
    return false;
  }
}

export function maskSIN(sin = '') {
  return sin.replace(/^.{6}/, '• • • • • • ');
}

export function formatSIN(sin = '') {
  // returns SIN '123456789' as '123 456 789'
  const sinPartsInArray = sin.match(/.{1,3}/g);
  return sinPartsInArray ? sinPartsInArray.join(' ') : sin;
}
