import { useInputState } from './useInputState';

const validations = [
  {
    validate: value => value && value.trim && !!value.trim(),
    errorMessage: 'dateOfBirth.monthError'
  },
  {
    validate: value => /^(0?[1-9]|1[0-2])$/.test(value),
    errorMessage: 'dateOfBirth.monthError'
  }
];

export function useBirthMonthInputState(options) {
  return useInputState({
    initialValue: null,
    validations,
    ...(options || {})
  });
}
