import { useEffect, useRef, useState } from "react";
import { useDebounce } from "./useDebounce";

export const useScrollDepth = ({
  containerRef,
  onScroll = null,
  onPercentageScroll = null,
  options = {}
}) => {
  const calledPercentages = useRef(new Set());
  const [scrollPercentage, setScrollPercentage] = useState(0);
  const {
    scrollDelay = 100,
    percentagePoints = [0, 25, 50, 75, 100]
  } = options || {};

  // If component does not unmount OR called directly with handleDebouncedScroll, force reset state
  const resetScrollDepth = () => {
    setScrollPercentage(0);
    calledPercentages.current = new Set();
  };

  // Trigger event based on provided number[]
  // Only will trigger each percentage checkpoint once
  const triggerPercentagePoint = (percentage) => {
    percentagePoints.forEach(point => {
      if (percentage >= point && !calledPercentages.current.has(point)) {
        calledPercentages.current.add(point);
        onPercentageScroll(point);
      }
    });
  };

  // Calculate scroll percentage, rounded up (0-100)
  const getScrollPercentage = (event) => {
    const scrollTop = event.target.scrollTop;
    const scrollHeight = event.target.scrollHeight;
    const clientHeight = event.target.clientHeight;
    const totalScrollLength = scrollHeight - clientHeight;

    // To avoid decimal return, round up
    const scrollPercentage = (scrollTop / totalScrollLength) * 100;
    return Math.ceil(scrollPercentage);
  };

  const handleScroll = (event) => {
    const percentage = getScrollPercentage(event);
    setScrollPercentage(percentage);

    // Trigger if all provided options are passed
    (onPercentageScroll && percentagePoints) && triggerPercentagePoint(percentage);
    (onScroll) && onScroll(percentage);
  };

  const handleDebouncedScroll = useDebounce(handleScroll, scrollDelay);

  // If ref is passed, emit listener for scroll events
  useEffect(() => {
    containerRef?.current?.addEventListener('scroll', handleDebouncedScroll);

    return () => {
      containerRef?.current?.removeEventListener('scroll', handleDebouncedScroll);
    };
  }, []);

  return {
    scrollPercentage,
    resetScrollDepth,
    handleDebouncedScroll
  };
};
