import { setAuthToken } from 'services/webApi';

export const processToken = (sessionStorage, token) => {
  if (token) {
    sessionStorage.setItem('token', token);
  } else {
    sessionStorage.removeItem('token');
  }

  setAuthToken(token);
};

export const processRefreshToken = (refreshToken) => {
  if (refreshToken) {
    sessionStorage.setItem('refreshToken', refreshToken);
  } else {
    sessionStorage.removeItem('refreshToken');
  }
};

export const useToken = (sessionStorage) => {
  const token = sessionStorage.getItem('token');
  if (token) {
    setAuthToken(token);
  }
};

export const processIdToken = (idToken) => {
  if (idToken) {
    sessionStorage.setItem('idToken', idToken);
  } else {
    sessionStorage.removeItem('idToken');
  }
};
