import { getEventCategoryFromLocation } from './eventCategories';
import { componentEvents } from './componentEvents';
import { sendEvent } from '../../webApi';
import { jsonpath } from '../../jsonpath';
import ReactGA from 'react-ga4';

const isLoggingEnabled = __IS_AZURE_EVENT_HUBS_ENABLED__ === 'true';
function getComponentEvent(componentName, eventName) {
  const component = componentEvents[componentName];
  const compEvent = component?.events[eventName];
  if (compEvent) {
    return {
      componentName: componentName,
      componentCode: component.code,
      eventName: eventName,
      eventHub: compEvent.eventHub,
      ga: compEvent.ga
    };
  }
  return null;
}
function processEventHub(eventCategory, event, options) {
  const sessionId = sessionStorage.getItem('sessionId') || '';
  const idToken = sessionStorage.getItem('idToken') || '';
  const data = options?.data;
  sendEvent({ sessionId, idToken, eventCategory, componentName: event.componentName, eventName: event.eventName, data })
  .catch(() => {});
}
function fillActionWithData(action, data) {
  return action.replace(/\[([^\]]+)\]/g, (match, path) => {
    const value = jsonpath.getValue(data, path);
    if (value === undefined) {
        return match; // Return the original match if the path is not found
    }
    return value;
  });
}
function processGA(eventCategory, event, options) {
  var action = event?.ga?.action;
  if (options?.data) {
    // Fill GA Action with Data from Option
    action = fillActionWithData(action, options.data);
  }
  ReactGA.event({
    category: eventCategory?.category,
    action: action,
    label: eventCategory?.label
  });
}
export function logComponentEvent(componentName, eventName, options) {
  if (componentName && eventName && isLoggingEnabled) {
    const event = getComponentEvent(componentName, eventName);
    const eventCategory = options?.eventCategory ?? getEventCategoryFromLocation();
    if (event?.eventHub) {
      processEventHub(eventCategory, event, options);
    }
    if (event?.ga) {
      processGA(eventCategory, event, options);
    }
  }
}
