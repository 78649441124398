import React from 'react';
import localeCopy from './localeCopy';

export const LocalizationContext = React.createContext({});

export const longLocaleNames = {
  'en-US': 'English',
  'fr-CA': 'French'
};

export function getLocalizedCopy(locale = 'en-US') {
  return localeCopy[locale] || {};
}

export function normalizeLocale(locale) {
  if (!locale) {
    locale = 'en-US';
  } else {
    if (locale.indexOf('en') !== -1) {
      locale = 'en-US';
    } else if (locale.indexOf('fr') !== -1) {
      locale = 'fr-CA';
    } else {
      locale = 'en-US';
    }
  }

  return locale;
}
