import React from 'react';
import PropTypes from 'prop-types';
import SuccessIcon from 'assets/icons/positive/success.svg';
import BgImage1 from 'assets/images/bg-image1.svg';
import BgImage2 from 'assets/images/bg-image2.svg';
import './styles/outage.scss';

export default function Outage({
  text,
  locale,
  localized
}) {
  const { title, message } = text || {};

  return (
    <div className="outage">
      <img className="outage__img" src={SuccessIcon} alt={localized['success']} />
      <img src={BgImage1} className="outage__bg-img-1" alt='bg-image1' />
      <img src={BgImage2} className="outage__bg-img-2" alt='bg-image2' />
      <h1 className="outage__title">
        {title && title[locale] || localized['outage.title']}
      </h1>
      <div className="outage__message">
        {message && message[locale] || localized['outage.message']}
      </div>
    </div>
  );
}

Outage.propTypes = {
  text: PropTypes.object,
  locale: PropTypes.string,
  localized: PropTypes.object
};
