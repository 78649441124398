import React from 'react';
import PropTypes from 'prop-types';
import { Alert as MUIAlert, AlertTitle } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorIcon from '@mui/icons-material/Error';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Link from 'components/common/Link';
import './styles/alert.scss';

export default function Alert({
  severity = 'success',
  className: propClassName,
  title,
  children,
  showBorder = false,
  titleBlock = false,
  icon,
  linkLabel,
  linkLocation,
  ...alertProps
}) {
  const severityPropMap = {
    success: {
      severity: 'success',
      className: 'alert--success'
    },
    info: {
      severity: 'info',
      className: 'alert--info'
    },
    error: {
      severity: 'error',
      className: 'alert--error'
    },
    warning: {
      severity: 'warning',
      className: 'alert--warning'
    },
    neutral: {
      severity: 'info',
      className: 'alert--neutral'
    }
  };

  const MUIAlertProps =
    (severity && severityPropMap[severity]) || severityPropMap['success'];
  const { className } = MUIAlertProps || {};

  return (
    <MUIAlert
      {...MUIAlertProps}
      className={`alert ${showBorder ? 'alert--show-border' : ''} ${titleBlock ? 'alert--title-block' : ''
        } ${className} ${propClassName || ''}`}
      iconMapping={{
        success: icon || <CheckCircleOutlineIcon />,
        error: icon || <ErrorIcon />,
        warning: icon || <InfoOutlinedIcon />,
        info: icon || <InfoOutlinedIcon />
      }}
      icon={icon}
      {...alertProps}
    >
      <div>
        {title && <AlertTitle>{title}</AlertTitle>}
        {children}
      </div>
      {linkLabel && (
        <div className="alert__link">
          <Link to={linkLocation}>{linkLabel}</Link>
        </div>
      )}
    </MUIAlert>
  );
}

Alert.propTypes = {
  severity: PropTypes.string,
  title: PropTypes.any,
  className: PropTypes.string,
  children: PropTypes.node,
  icon: PropTypes.node,
  showBorder: PropTypes.bool,
  titleBlock: PropTypes.bool,
  linkLabel: PropTypes.string,
  linkLocation: PropTypes.string
};
