import React from 'react';
import { browserHistory, Router } from 'react-router';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga4';
import materialTheme from 'styles/materialTheme';
import { ThemeProvider } from '@mui/material';
import {
  Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, ArcElement,
  Title, Tooltip, Legend, Filler
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

// Register plugins for all charts
ChartJS.register(
  CategoryScale, LinearScale, PointElement, LineElement, ArcElement,
  Title, Tooltip, Legend, Filler, ChartDataLabels
);

export default function App({ routes }) {
  function onRouteUpdate() {
    window.scrollTo(0, 0);
    if (!__DEV__) {
      ReactGA.send({
        page: window.location.pathname + window.location.search,
        hitType: 'pageview',
        title: window.location.pathname + window.location.search
      });
    }
  }

  return (
    <ThemeProvider theme={materialTheme}>
      <div style={{ height: '100%', overflowY: 'hidden' }}>
        <Router history={browserHistory} children={routes}
          onUpdate={onRouteUpdate} />
      </div>
    </ThemeProvider>
  );
}

App.propTypes = {
  routes: PropTypes.array
};
