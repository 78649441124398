const jsonPath = require('jsonpath');
function getValue(data, path) {
  try {
    return jsonPath.value(data, path) ?? path;
  } catch {
    return path;
  }
};
function fillObject(obj, data) {
  let filledObj = {};
  if (typeof obj === 'object' && obj !== null) {
    for (const key in obj) {
      if (obj.hasOwn(key)) {
        if (typeof obj[key] === 'object' && obj[key] !== null) {
          filledObj[key] = fillObject(obj[key], data);
        } else {
          filledObj[key] = getValue(data, obj[key]);
        }
      }
    }
  } else {
    filledObj = getValue(data, obj);
  }
  return filledObj;
}
export const jsonpath = {
  getValue,
  fillObject
};
