import { useMemo } from 'react';
import { usePasswordInputState } from './usePasswordInputState';

export function useConfirmPasswordInputState({ password }) {
  const validations = useMemo(() => [
    {
      validate: value => value === password,
      errorMessage: 'confirmPasswordMismatchError'
    }
  ], [password]);

  return usePasswordInputState({
    initialValue: '',
    validations
  });
};
