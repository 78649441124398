import { axios } from './config';

export function setAuthToken(token) {
  if (token) {
    axios.defaults.headers['authorization'] = 'Bearer ' + token;
  } else {
    axios.defaults.headers['authorization'] = null;
  }
}

export function checkAuthTokenSet() {
  return !!axios.defaults.headers['authorization'];
}

export function invalidateToken() {
  axios.defaults.headers['authorization'] = null;
}

export const genericError = {
  'en-US': "Something's not right. Please try again later.",
  'fr-CA': 'Quelque chose ne va pas. Veuillez réessayer plus tard.'
};

export const tryAgainError = {
  'en-US': "Something's not right. Please try again.",
  'fr-CA': 'Quelque chose ne va pas. Veuillez réessayer.'
};

export function authenticateAzureUser(body) {
  const reqUrl = '/oauth';
  return axios.post(reqUrl, body);
}

export function parseErrorMessage(response, locale = 'en-US') {
  if (!response) {
    return null;
  }
  // function to extra error message from various responses
  if (typeof response === 'string') {
    return response;
  }
  if (response.response) {
    if (response.response.data) {
      if (response.response.data.errors && response.response.data.errors.length > 0) {
        if (typeof response.response.data.errors[0] === 'string') {
          const genericErrorFromAxios = 'Request failed with status code';
          const hasAxiosError = response.response.data.errors[0].includes(genericErrorFromAxios);
          return hasAxiosError || locale !== 'en-US'
            ? genericError[locale]
            : response.response.data.errors[0];
        }
        if (response.response.data.errors[0] && response.response.data.errors[0]['title'] && response.response.data.errors[0]['message']) {
          const title = response.response.data.errors[0]['title'][locale];
          const message = response.response.data.errors[0]['message'][locale];
          return {
            title,
            message
          };
        }
        if (response.response.data.errors[0] && response.response.data.errors[0]['title'] && response.response.data.errors[0]['source']) { // coconut errors
          return {
            message: response.response.data.errors[0]['title']
          };
        }
        return response.response.data.errors[0] && response.response.data.errors[0][locale] || genericError[locale];
      }
      if (response.response.data.errfor) {
        let errorMessage = '';
        for (const key in response.response.data.errfor) {
          errorMessage += key + ' ' + response.response.data.errfor[key] + ' ';
        }
        return errorMessage;
      }
    }
  }
  if (response.code === 'ERR_NETWORK') {
    return tryAgainError[locale];
  }
  if (response && response.errors && response.errors[0]) {
    return response.errors[0];
  }
  return genericError[locale];
}

export * from './event';
export * from './setting';
export * from './stripe';
export * from './gift';
