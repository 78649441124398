import React from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import { IconButton as MUIIconButton } from '@mui/material';
import './styles/iconButton.scss';

const variantPropMap = {
  'avatar': {
    variant: 'avatar',
    className: 'iconButton--avatar'
  },
  'filled': {
    variant: 'contained',
    className: 'iconButton--filled'
  },
  'outlined': {
    variant: 'outlined',
    className: 'iconButton--outlined'
  },
  'component': {
    variant: 'outlined',
    className: 'iconButton--component'
  }
};

export default function IconButton({
  className: propClassName,
  variant,
  children,
  href,
  isExternalLink = false,
  onClick,
  ...buttonProps
}) {
  const MUIIconButtonProps = variant && variantPropMap[variant];
  const { className: variantClassName } = MUIIconButtonProps || {};
  function handleButtonClick(e) {
    if (!isExternalLink) {
      if (href) {
        e.preventDefault && e.preventDefault();
        browserHistory.push(href);
      }
      onClick && onClick(e);
    }
  }

  return (
    <MUIIconButton
      className={`${variantClassName} ${propClassName}`}
      onClick={handleButtonClick}
      href={href}
      disableRipple={variant === 'component'}
      disableFocusRipple
      {...buttonProps}>
      {children}
    </MUIIconButton>
  );
}

IconButton.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.string,
  children: PropTypes.node,
  href: PropTypes.string,
  isExternalLink: PropTypes.bool,
  onClick: PropTypes.func
};
