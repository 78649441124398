/* eslint-disable quotes */
/* eslint-disable max-len */
export default {
  'embark': 'Embark',
  'embarkStudentCorp': 'Embark étudiant corp.',
  'home.footerCopyrightSymbol': '©',
  'home.footerAllRightsReserved': 'Tous droits réservés.',
  'home.footerTrademarkText': "Embark est une marque de commerce d'Embark Student Corp.",
  'outage.title': 'Nous serons bientôt de retour en ligne.',
  'outage.message': 'Notre site est actuellement fermé pour cause de maintenance. Des mises à niveau passionnantes arrivent !',
  'register': "S'inscrire",
  'continue': "Continuer",
  'next': 'Suivante',
  'send': 'Envoyer',
  'retry': 'Refaire',
  'done': "Fait",
  'copy': 'Copie',
  'copied': 'Copié !',
  'cancel': "Annuler",
  'update': "Mise à jour",
  'skip': "Sauter",
  'and': "et",
  'note': "Noter:",
  'his': 'son',
  'her': 'son',
  'their': 'leur',
  'on': 'sur',
  'is': 'est',
  'disagree': "être en désaccord",
  'decline': "Refuser",
  'name': 'Nom',
  'change': 'Modifier',
  'remove': 'Supprimer',
  'noKeep': 'Non, conserver',
  'yesRemove': 'Oui, supprimer',
  'share': 'Partager',
  'getStarted': 'Commencer',
  'confirmRemove': 'Êtes-vous certain de vouloir supprimer ce document ?',
  'giftDetails.title': 'Montant du cadeau',
  'giftDetails.banner.title': 'Combien voulez-vous offrir?',
  'giftDetails.banner.description': "Choisissez le montant que vous souhaitez envoyer.Vous pouvez offrir aussi peu que 25 $ et jusqu'à un maximum de 2 500 $.",
  'giftDetails.amount.input.label': 'Combien aimeriez-vous offrir?',
  'giftDetails.amount.input.textFieldLabel': 'Montant personnalisé',
  'giftDetails.message.input.label': 'Ajouter un message (facultatif)',
  'giftDetails.message.input.placeholder': 'Un petit quelque chose pour vous.Je vous souhaite du succès et de la joie à venir!',
  'giftDetails.about.label': 'Envoyer une note-cadeau',
  'giftDetails.about.description': 'Faites-leur savoir que vous pensez à eux avec un message personnalisé.',
  'giftDetails.options.custom': 'Coutume',
  'giftDetails.error.amountEmpty': 'Veuillez sélectionner une option pour continuer.',
  'giftDetails.error.amountUnderMin': 'Entrez un minimum de 25 $.',
  'giftDetails.error.amountOverMax': 'Entrez un maximum de 2,500 $.',
  'giftDetails.error.exceededMaximumCharacter': 'Le message du cadeau doit contenir 1000 caractères ou moins.',
  'giftDetails.error.allFieldsRequired': 'Veuillez compléter tous les détails pour continuer',
  'giftCompliance.title': 'Nous avons besoin de quelques détails',
  'giftCompliance.banner.title': 'Quelle est votre relation?',
  'giftCompliance.banner.description': "Nous demandons ces informations pour nous assurer que nous sommes conformes aux directives du gouvernement pour les plans d'épargne (RESP) enregistrés.",
  'giftCompliance.relationship.heading': "Quelle est votre relation avec l'enfant?",
  'giftCompliance.relationship.dropdown.label': 'Relation',
  'giftCompliance.relationship.dropdown.grandparent': 'Grand-parent',
  'giftCompliance.relationship.dropdown.aunt': 'Tante',
  'giftCompliance.relationship.dropdown.uncle': 'Oncle',
  'giftCompliance.relationship.dropdown.sibling': 'Frère et sœur',
  'giftCompliance.relationship.dropdown.parent': 'Parente',
  'giftCompliance.relationship.dropdown.notRelated': 'Non lié',
  'giftCompliance.relationship.dropdown.other': 'Autre',
  'giftCompliance.error.noRelationship': 'Veuillez sélectionner une option pour continuer.',
  'giftError.title.expired': 'Quelque chose ne va pas',
  'giftError.description.expired': "Il semble que le lien de dons a expiré.Veuillez demander à l'abonné de créer un nouveau lien.",
  'facebook': 'Facebook',
  'x': 'X',
  'whatsApp': 'WhatsApp',
  'reddit': 'Reddit',
  'linkedIn': 'LinkedIn',
  'img.alt.annulus': `Une forme d'anneau coupée en deux`,
  'img.alt.schoolPin': "Icône d'un capuchon de graduation",
  'img.alt.clippedSquarePattern': 'Petit carré',
  'img.alt.smallCirclePattern': 'Petit cercle',
  'img.alt.extraLargeCirclePattern': 'cercle très grand',
  'img.alt.professionsSearchRocketTelescope': `Illustration d'une personne bleue regardant à travers un télescope portant un jetpack`,
  'img.alt.largeCirclePattern': 'Grand cercle',
  'img.alt.halfCirclePattern': 'Demi cercle',
  'img.alt.diamondPattern' : 'Diamant',
  'img.alt.noSearchOptionsIllustration': `Illustration d'une antenne ne recevant aucun signal`,
  'img.alt.professionsSearchIllustration' : `Illustration d'une personne bleue naviguant sur Internet`,
  'img.alt.emailIcon': 'Email',
  'img.alt.searchIcon': 'Recherche',
  'img.alt.closeIcon': 'Fermer',
  'img.alt.arrowBackIcon': 'Flèche arrière',
  'img.alt.programScholarIcon': 'Icône de la loupe de fouille à travers du papier',
  'img.alt.sinDrivingLicense': 'Permis de conduire NAS',
  'img.alt.successIcon': 'Icône de réussite',
  'img.alt.apolloIcon': `Icône de l'entreprise Apollo`,
  'img.alt.youthfullyIcon': `Icône de l'entreprise Youthfully`,
  'img.alt.willfulIcon': `Icône de l'entreprise Willful`,
  'img.alt.borrowellIcon': `Icône de l'entreprise de Borrowell`,
  'img.alt.neoFinancialIcon': `Icône de l'entreprise de NeoFinancial`,
  'img.alt.policyMe': `Icône de l'entreprise de Assure Moi`,
  'img.alt.facebookIcon': `Icône de l'entreprise de Facebook`,
  'img.alt.xIcon': `Icône de l'entreprise de X`,
  'img.alt.whatsAppIcon': `Icône de l'entreprise de WhatsApp`,
  'img.alt.redditIcon': `Icône de l'entreprise de Reddit`,
  'img.alt.linkedInIcon': `Icône de l'entreprise de LinkedIn`,
  'img.alt.specialization': 'Notifier',
  'img.alt.btnPlusIcon': 'Icône Plus',
  'img.alt.btnTransferIcon': 'Icône de transfert',
  'img.alt.trendingUpIcon': 'Tendance à la hausse',
  'img.alt.iq.yellowArrow': 'flèche jaune sans queue',
  'img.alt.iq.thumbsUp': 'Bravo',
  'img.alt.iq.thumbsDown': 'Pouce vers le bas',
  'img.alt.giftLinkError': 'Erreur de liaison',
  'img.alt.giftCircle': 'Petite boîte actuelle',
  'img.alt.embarkGift': 'Embark Gifting',
  'gifting.about.headerOne': 'You’ve been invited to gift to',
  'gifting.about.headerTwo': 'RESP',
  'gifting.about.bodyOne': 'Your gift to',
  'gifting.about.bodyTwo': 'RESP will play a crucial role in shaping their future. It will support their educational aspirations, empowering them to achieve their dreams.',
  'gifting.about.button': 'Gift now',
  'gifting.list.header': 'About Embark Gifting',
  'gifting.list.one.header': 'Gifting is safe and secure',
  'gifting.list.one.body': 'You can securely and conveniently gift using Credit Cards, Apple Pay and Google Pay. Everything is encrypted and safe.',
  'gifting.list.two.header': 'A gift that keeps on giving',
  'gifting.list.two.body': 'You can gift up to $1,000, and your gift could qualify for an extra 20% in government grants. Your gift is highly appreciated, but please note that this gift is not considered a donation, and as a result, gifters will not receive tax receipts.',
  'gifting.list.three.header': 'Your relationship',
  'gifting.list.three.body': 'We need to ask you some questions about your relationship with the gift recipient to comply with government regulations.',
  'gifting.about.terms.body': 'By sending a gift to an Embark child, you are consenting to the',
  'gifting.about.terms.link': 'Terms and conditions',
  'gifting.about.terms.alt': 'redirect icon',
  'gifting.review.header': 'Let’s quickly review',
  'gifting.review.name': 'Embark gift recipient:',
  'gifting.review.amount': 'Embark RESP gift total:',
  'gifting.review.processingFee': 'Processing fee:',
  'gifting.review.total': 'Total amount:',
  'gifting.review.message': 'Gift message:',
  'gifting.review.button': 'Pay and send gift',
  'gifting.review.dialog.header': 'Processing fee',
  'gifting.review.dialog.body': 'The bank that issues the payment card charges a fee to cover the service of handling the transaction. We do our best to keep them as low as possible.',
  'footer.embark': 'Embark.',
  'footer.rightsReserved': 'Tous droits réservés.',
  'footer.trademarkLine2': "Embark est une marque commerciale d'Embark Student Corp.",
  'networkError.title': "Quelque chose ne va pas. Veuillez réessayer plus tard.",
  'gifting.success.header': 'Your Embark RESP gift has been sent!',
  'gifting.success.body': 'You will receive a receipt in your inbox, and we will notify the Embark customer that your gift is on the way!',
  'gifting.success.button': 'Done'
};
