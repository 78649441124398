import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { getGiftError } from './services';
import { getQueryParameter } from 'services/queryParameters';
import './styles/giftError.scss';

function GiftError({ localized }) {
  const reason = getQueryParameter('reason');
  const { img, localizedKey } = getGiftError(reason);

  useEffect(() => {
    sessionStorage.removeItem('token');
  }, []);

  return (
    <div className='gift-error'>
      <img
        className='gift-error__img'
        src={img.src}
        alt={localized[img.alt] || ''}
      />
      <h1 className='gift-error__heading'>
        {localized[localizedKey.title] || ''}
      </h1>
      <p className='gift-error__description'>
        {localized[localizedKey.description] || ''}
      </p>
    </div>
  );
}

GiftError.propTypes = {
  localized: PropTypes.object
};

export default GiftError;
