import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import './styles/link.scss';
// styles come from coreLayout.scss too

export default function Link({
  className,
  isButton = false,
  hasEndIcon = false,
  linkIcon,
  opensInNewTab = false,
  children,
  ...linkProps
}) {
  function renderEndIcon() {
    return (
      <span
        className={
          'link__content__end-icon' +
          (className ? ` ${className}__content__end-icon` : '')
        }
      >
        {linkIcon && (
          <img
            src={linkIcon}
            aria-hidden
          />
        ) || (
        <OpenInNewIcon aria-hidden fontSize="inherit" />
          )}
      </span>
    );
  }
  return (
    <Fragment>
      {
        isButton
          ? <button type="button" className={'link' + (className ? ` ${className}` : '')} {...linkProps}>
            {children}
          </button>
          : <RouterLink
            className={'link' + (className ? ` ${className}` : '')}
            {...(opensInNewTab && { target: '_blank', rel: 'noopener noreferrer' })}
            {...linkProps}
          >
            <span className="link__content">
              {children}
              {hasEndIcon && renderEndIcon()}
            </span>
          </RouterLink>
      }
    </Fragment>
  );
}

Link.propTypes = {
  isButton: PropTypes.bool,
  className: PropTypes.string,
  hasEndIcon: PropTypes.bool,
  linkIcon: PropTypes.string,
  opensInNewTab: PropTypes.bool,
  children: PropTypes.node
};
