import { useInputState } from './useInputState';
import { validateNameFormat, validateNameLength } from '../name';

const validations = [
  {
    validate: value => value && value.trim && !!value.trim(),
    errorMessage: 'firstNameRequiredError'
  },
  {
    validate: value => validateNameFormat(value),
    errorMessage: 'invalidFirstName'
  },
  {
    validate: value => validateNameLength(value),
    errorMessage: 'invalidNameLength'
  }
];

export function useFirstNameInputState(options) {
  return useInputState({
    initialValue: '',
    validations,
    ...(options || {})
  });
}
