// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home{align-items:center;display:flex;flex-direction:column;height:100%;justify-content:center}`, "",{"version":3,"sources":["webpack://./src/components/Home/styles/home.scss"],"names":[],"mappings":"AAMA,MAGE,kBAAA,CAFA,YAAA,CACA,qBAAA,CAGA,WAAA,CADA,sBAuKF","sourcesContent":["@use 'styles/typography' as *;\n@use 'styles/spacing' as *;\n@use 'styles/colors' as *;\n@use 'styles/border-radius.scss' as *;\n@use 'src/styles/breakpoints.scss' as *;\n\n.home {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  height: 100%;\n}"],"sourceRoot":""}]);
// Exports
export var primary = `#1c1463`;
export var primary100 = `#fff`;
export var primary99 = `#fffbff`;
export var primary95 = `#f3eeff`;
export var primary90 = `#e3dfff`;
export var primary80 = `#c4c0ff`;
export var primary70 = `#a6a1fc`;
export var primary60 = `#8b87df`;
export var primary50 = `#726dc3`;
export var primary40 = `#5954a8`;
export var primary30 = `#413b8e`;
export var primary20 = `#2b2386`;
export var primary10 = `#130463`;
export var primary0 = `#000`;
export var secondary = `#594ff0`;
export var secondaryContainer = `#e3dfff`;
export var secondary100 = `#fff`;
export var secondary99 = `#fffbff`;
export var secondary95 = `#f2efff`;
export var secondary90 = `#e3dfff`;
export var secondary80 = `#c3c0ff`;
export var secondary75 = `#b0adf5`;
export var secondary70 = `#a4a1ff`;
export var secondary60 = `#8681ff`;
export var secondary50 = `#685fff`;
export var secondary40 = `#4e43e5`;
export var secondary30 = `#3420ce`;
export var secondary20 = `#1f00a5`;
export var secondary10 = `#100069`;
export var secondary0 = `#000`;
export var tertiary = `#96c7f0`;
export var tertiary100 = `#fff`;
export var tertiary99 = `#f5f9fd`;
export var tertiary95 = `#ddebf8`;
export var tertiary90 = `#cbe6ff`;
export var tertiary80 = `#8fcdff`;
export var tertiary70 = `#5cb3f0`;
export var tertiary60 = `#3c98d4`;
export var tertiary50 = `#0d7eb8`;
export var tertiary40 = `#006494`;
export var tertiary30 = `#004b71`;
export var tertiary20 = `#00344f`;
export var tertiary10 = `#001e30`;
export var tertiary0 = `#000`;
export var neutral = `#000`;
export var neutral100 = `#fff`;
export var neutral99 = `#fbfdfd`;
export var neutral95 = `#f4f6f7`;
export var neutral90 = `#e0e3e3`;
export var neutral80 = `#c4c7c7`;
export var neutral70 = `#a9acac`;
export var neutral60 = `#8e9192`;
export var neutral50 = `#747778`;
export var neutral40 = `#5c5f60`;
export var neutral30 = `#444748`;
export var neutral20 = `#2d3132`;
export var neutral10 = `#191c1d`;
export var neutral0 = `#000`;
export var neutralVariant100 = `#fff`;
export var neutralVariant99 = `#f9fcff`;
export var neutralVariant95 = `#ebf2f8`;
export var neutralVariant90 = `#dde3ea`;
export var neutralVariant80 = `#c0c7cd`;
export var neutralVariant70 = `#a5acb2`;
export var neutralVariant60 = `#8b9298`;
export var neutralVariant50 = `#71787e`;
export var neutralVariant40 = `#585f64`;
export var neutralVariant30 = `#41484d`;
export var neutralVariant20 = `#2a3136`;
export var neutralVariant10 = `#161c20`;
export var neutralVariant0 = `#000`;
export var highlight = `#dbff45`;
export var highlight100 = `#fff`;
export var highlight99 = `#fbffdf`;
export var highlight95 = `#ddff56`;
export var highlight90 = `#cef137`;
export var highlight80 = `#b2d40f`;
export var highlight70 = `#99b700`;
export var highlight60 = `#819b00`;
export var highlight50 = `#6a8000`;
export var highlight40 = `#546500`;
export var highlight30 = `#3f4c00`;
export var highlight20 = `#2a3400`;
export var highlight10 = `#181e00`;
export var highlight0 = `#000`;
export var success = `#0f984a`;
export var success100 = `#fff`;
export var success99 = `#f5fff2`;
export var success95 = `#c4ffcb`;
export var success90 = `#84fba0`;
export var success80 = `#68de86`;
export var success70 = `#4ac16e`;
export var success60 = `#0f984a`;
export var success50 = `#008a41`;
export var success40 = `#006d32`;
export var success30 = `#005224`;
export var success20 = `#003917`;
export var success10 = `#00210b`;
export var success0 = `#000`;
export var warning = `#e9910c`;
export var warning100 = `#fff`;
export var warning99 = `#fffbf7`;
export var warning95 = `#fed`;
export var warning90 = `#ffdcb8`;
export var warning80 = `#ffb863`;
export var warning70 = `#f09717`;
export var warning60 = `#cf7e00`;
export var warning50 = `#ab6700`;
export var warning40 = `#895200`;
export var warning30 = `#683d00`;
export var warning20 = `#492900`;
export var warning10 = `#2c1700`;
export var warning0 = `#000`;
export var accent = `#faccb8`;
export var accent100 = `#fff`;
export var accent99 = `#fffbff`;
export var accent95 = `#ffede6`;
export var accent90 = `#ffdbcc`;
export var accent80 = `#ffb694`;
export var accent70 = `#f8905d`;
export var accent60 = `#d87746`;
export var accent50 = `#b85e30`;
export var accent40 = `#994719`;
export var accent30 = `#7a3002`;
export var accent20 = `#571f00`;
export var accent10 = `#351000`;
export var accent0 = `#000`;
export var info = `#0d7eb8`;
export var lightInfoContainer = `#e6f2ff`;
export var info100 = `#fff`;
export var info99 = `#f5f9fd`;
export var info95 = `#ddebf8`;
export var info90 = `#cbe6ff`;
export var info80 = `#8fcdff`;
export var info70 = `#5cb3f0`;
export var info60 = `#3c98d4`;
export var info50 = `#0d7eb8`;
export var info40 = `#006494`;
export var info30 = `#004b71`;
export var info20 = `#00344f`;
export var info10 = `#001e30`;
export var info0 = `#000`;
export var error = `#fa4f00`;
export var error100 = `#fff`;
export var error99 = `#fffbff`;
export var error95 = `#ffede8`;
export var error90 = `#ffdbd0`;
export var error80 = `#ffb59d`;
export var error70 = `#ff8b65`;
export var error60 = `#ff5714`;
export var error50 = `#d74300`;
export var error40 = `#ac3400`;
export var error30 = `#842600`;
export var error20 = `#5d1800`;
export var error10 = `#390b00`;
export var error0 = `#000`;
var _1 = `#fff`;
export { _1 as "light-surface" };
var _2 = `#f3f3f7`;
export { _2 as "light-surface-2" };
var _3 = `#e8e7ef`;
export { _3 as "light-surface-3" };
var _4 = `#dcdbe7`;
export { _4 as "light-surface-4" };
var _5 = `#d1d0df`;
export { _5 as "light-surface-5" };
var _6 = `#c6c4d8`;
export { _6 as "light-surface-6" };
var _7 = `#1c1463`;
export { _7 as "dark-surface" };
var _8 = `#221971`;
export { _8 as "dark-surface-2" };
var _9 = `#281f7f`;
export { _9 as "dark-surface-3" };
var _a = `#2e258d`;
export { _a as "dark-surface-4" };
var _b = `#342b9b`;
export { _b as "dark-surface-5" };
var _c = `#3a31a9`;
export { _c as "dark-surface-6" };
export default ___CSS_LOADER_EXPORT___;
