import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import './styles/imageUploading.scss';
import palette from 'styles/materialTheme/palette';

export default function ImageUploading({
  croppedImage,
  localized
}) {
  return (
    <div className='image-uploading'>
      <div className='image-uploading__cropped'>
        <svg className='image-uploading__cropped__svg'>
          <defs>
            <linearGradient id='my_gradient' x1='0%' y1='0%' x2='0%' y2='100%'>
              <stop offset='0%' stopColor={palette.secondary.main} />
              <stop offset='100%' stopColor={palette.neutral[100]} />
            </linearGradient>
          </defs>
        </svg>
        <CircularProgress
          size={255}
          variant='indeterminate'
          thickness={5}
          color='secondary'
          sx={{
            position: 'absolute',
            top: '0.8rem',
            left: '0.7rem',
            animationDuration: '3s',
            'svg circle': {
              stroke: 'url(#my_gradient)',
              animationDuration: '2s'
            }

          }}
        />
        {croppedImage ? <img src={croppedImage} alt='Cropped' className='image-uploading__cropped__img' /> : ''}
      </div>
      <p className='image-uploading__text'>{localized['imageUploading.text']}</p>
    </div>
  );
}

ImageUploading.propTypes = {
  croppedImage: PropTypes.string,
  localized: PropTypes.object
};
