import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from 'components/common/Dialog';
import ImagePreview from './components/ImagePreview';
import ImageDrop from './components/ImageDrop';
import ImageChangeConfirm from './components/ImageChangeConfirm';

export default function ImageUploadDialog({
  name,
  localized,
  locale,
  isOpen,
  isLoading,
  setIsLoading,
  onClose,
  upload,
  removePicture,
  profileImageSrc,
  beneficiaryAccountId,
  planAccountId,
  profilePictureBlobId
}) {
  const [fileObject, setFileObject] = useState(null); // File API
  const [uncroppedImage, setUncroppedImage] = useState(null); // base64 string
  const [croppedImage, setCroppedImage] = useState(null); // base64 string
  const [isEditing, setIsEditing] = useState(false);
  const [isPictureDeleted, setIsPictureDeleted] = useState(false);
  /**
   * @param {File} image
   */
  function handleSetFile(image) {
    setFileObject(image);
    // file -> base64 text
    const fileReader = new FileReader();
    fileReader.readAsDataURL(image);
    fileReader.onload = () => setUncroppedImage(fileReader.result);
  }

  function handleSetUncroppedImage(base64ImageToSet) {
    // clear file object when base64 text is cleared
    if (base64ImageToSet) {
      setUncroppedImage(base64ImageToSet);
    } else {
      resetImage();
    }
  }

  function handleClose(forceClose) {
    if (isLoading && !forceClose) {
      return;
    }

    if (uncroppedImage) {
      resetImage();
    }
    onClose();
    setIsEditing(false);
    setIsPictureDeleted(false);
  }

  function resetImage() {
    setFileObject(null);
    setUncroppedImage(null);
    setCroppedImage(null);
  }

  function renderDialogContent() {
    if (profileImageSrc && !isEditing || isPictureDeleted) {
      return (
        <ImageChangeConfirm
          name={name}
          locale={locale}
          localized={localized}
          onClose={() => handleClose()}
          profileImageSrc={profileImageSrc}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          setIsEditing={setIsEditing}
          removePicture={removePicture}
          isPictureDeleted={isPictureDeleted}
          setIsPictureDeleted={setIsPictureDeleted}
          profilePictureBlobId={profilePictureBlobId}
        />
      );
    } else {
      return uncroppedImage
      ? (
        <ImagePreview
          localized={localized}
          file={uncroppedImage}
          fileType={fileObject && fileObject.type || ''}
          setFile={handleSetUncroppedImage}
          isLoading={isLoading}
          locale={locale}
          setIsLoading={setIsLoading}
          croppedImage={croppedImage}
          setCroppedImage={setCroppedImage}
          upload={upload}
          onClose={(forceClose) => handleClose(forceClose)}
          beneficiaryAccountId={beneficiaryAccountId}
          planAccountId={planAccountId}
        />
      ) : (
        <ImageDrop
          localized={localized}
          setFile={handleSetFile}
          onClose={() => handleClose()}
        />
      );
    }
  }

  return (
    <Dialog
      isOpen={isOpen}
      onClose={() => handleClose()}
      renderContent={() => renderDialogContent()}
    />
  );
}

ImageUploadDialog.propTypes = {
  name: PropTypes.shape({
    first: PropTypes.string,
    last: PropTypes.string
  }),
  localized: PropTypes.object,
  locale: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  isLoading: PropTypes.bool,
  setIsLoading: PropTypes.func,
  upload: PropTypes.func,
  removePicture: PropTypes.func,
  profileImageSrc: PropTypes.string,
  beneficiaryAccountId: PropTypes.string,
  planAccountId: PropTypes.string,
  profilePictureBlobId: PropTypes.string
};
