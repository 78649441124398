import Gift from "components/Gift";
import GiftError from "components/Gift/components/GiftError";
import GiftSuccess from "components/Gift/components/GiftSuccess";
import GiftDetails from "components/Gift/components/GiftDetails";
import GiftCompliance from "components/Gift/components/GiftCompliance";
import GiftAbout from "components/Gift/components/GiftAbout";
import GiftReview from "components/Gift/components/GiftReview";

export default {
  path: 'gift',
  component: Gift,
  indexRoute: { component: GiftAbout },
  childRoutes: [
    { path: 'error', component: GiftError },
    { path: 'details', component: GiftDetails },
    { path: 'compliance', component: GiftCompliance },
    { path: 'review', component: GiftReview },
    { path: 'success', component: GiftSuccess },
    { path: ':id', component: GiftAbout }
  ]
};
