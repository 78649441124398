import {
  secondary,
  secondary100,
  secondary99,
  secondary95,
  secondary90,
  secondary80,
  secondary75,
  secondary70,
  secondary60,
  secondary50,
  secondary40,
  secondary30,
  secondary20,
  secondary10,
  secondary0
} from 'styles/colors.scss';

export default {
  100: secondary100,
  99: secondary99,
  95: secondary95,
  90: secondary90,
  80: secondary80,
  75: secondary75,
  70: secondary70,
  60: secondary60,
  50: secondary50,
  40: secondary40,
  30: secondary30,
  20: secondary20,
  10: secondary10,
  0: secondary0,
  main: secondary,
  light: secondary40,
  dark: secondary80,
  contrastText: secondary100
};
