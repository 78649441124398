import {
  neutralVariant100,
  neutralVariant99,
  neutralVariant95,
  neutralVariant90,
  neutralVariant80,
  neutralVariant70,
  neutralVariant60,
  neutralVariant50,
  neutralVariant40,
  neutralVariant30,
  neutralVariant20,
  neutralVariant10,
  neutralVariant0
} from 'styles/colors.scss';

export default {
  100: neutralVariant100,
  99: neutralVariant99,
  95: neutralVariant95,
  90: neutralVariant90,
  80: neutralVariant80,
  70: neutralVariant70,
  60: neutralVariant60,
  50: neutralVariant50,
  40: neutralVariant40,
  30: neutralVariant30,
  20: neutralVariant20,
  10: neutralVariant10,
  0: neutralVariant0,
  main: neutralVariant40,
  light: neutralVariant90,
  dark: neutralVariant30,
  contrastText: neutralVariant100
};
