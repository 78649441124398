import { useInputState } from './useInputState';
import { validateEmailFormat, validateMissingEmail, validateEmailLength } from '../email';

const validations = [
  {
    validate: value => validateMissingEmail(value),
    errorMessage: 'pleaseEnterEmail'
  },
  {
    validate: value => validateEmailFormat(value),
    errorMessage: 'invalidEmail'
  },
  {
    validate: value => validateEmailLength(value),
    errorMessage: 'invalidEmailLength'
  }
];

export function useEmailInputState(options) {
  return useInputState({
    initialValue: '',
    validations,
    ...(options || {})
  });
};
