import { useInputState } from './useInputState';

const validations = [
  {
    validate: value => value && value.trim && !!value.trim(),
    errorMessage: 'cityRequiredError'
  }
];

export function useCityInputState(options) {
  return useInputState({
    initialValue: '',
    validations,
    ...(options || {})
  });
}
