import {
  neutral,
  neutral100,
  neutral99,
  neutral95,
  neutral90,
  neutral80,
  neutral70,
  neutral60,
  neutral50,
  neutral40,
  neutral30,
  neutral20,
  neutral10,
  neutral0
} from 'styles/colors.scss';

export default {
  100: neutral100,
  99: neutral99,
  95: neutral95,
  90: neutral90,
  80: neutral80,
  70: neutral70,
  60: neutral60,
  50: neutral50,
  40: neutral40,
  30: neutral30,
  20: neutral20,
  10: neutral10,
  0: neutral0,
  main: neutral,
  light: neutral99,
  dark: neutral10,
  contrastText: neutral100
};
