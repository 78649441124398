import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import AccountToolbar from 'components/common/AccountToolbar';
import IconButton from 'components/common/IconButton';
import questionMarkIcon from 'assets/icons/question_mark_icon.svg';
import Button from 'components/common/Button';
import ReviewDialog from './components/ReviewDialog';
import { formatCurrency } from 'services/currency';
import Footer from 'components/common/Footer';
import EmbarkGiftImg from 'assets/images/embark_gifting.png';
import { logComponentEvent } from 'services/logging/componentEvent';
import { browserHistory } from 'react-router';
import './styles/giftReview.scss';

export default function GiftReview({
    locale,
    localized,
    beneficiary,
    selectedGiftAmount,
    totalProcessingFee,
    checkoutMessage,
    isPaymentLoading,
    submitCheckout
  }) {
  const { firstName } = beneficiary || {};
  const formattedFee = (totalProcessingFee / 100);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  useEffect(() => {
    logComponentEvent('GiftingReview', 'PageLoaded');
  }, []);

  return (
    <div className='gift-review'>
      <div className='gift-review__content'>
        <div className='gift-review__content__left'>
          <AccountToolbar
            title={localized[`gifting.review.header`]}
            className='gift-review__content__left__toolbar'
            onBackClick={() => {
              logComponentEvent(
                'GiftingReview',
                'BackClicked',
                { data: { button: 'Back' } }
              );
              browserHistory.push('/gift/compliance');
            }}
          />
          <img
            src={EmbarkGiftImg}
            alt={localized['img.alt.embarkGift']}
            className='gift-review__content__left__img'
          />
        </div>
        <div className='gift-review__content__right'>
          <div className='gift-review__content__right__name'>
            <div className='gift-review__content__right__name__label'>
              {localized['gifting.review.name']}
            </div>
            <div className='gift-review__content__right__name__text'>
              {firstName}
            </div>
          </div>
          <div className='gift-review__content__right__amount'>
            <div className='gift-review__content__right__amount__label'>
              {localized['gifting.review.amount']}
            </div>
            <div className='gift-review__content__right__amount__text'>
              {formatCurrency(selectedGiftAmount, locale)}
            </div>
          </div>
          <div className='gift-review__content__right__fee'>
            <div className='gift-review__content__right__fee__label'>
              {localized[`gifting.review.processingFee`]}
              <IconButton onClick={() => {
                logComponentEvent(
                  'GiftingReview',
                  'ProcessingFeeDialog',
                  { data: { action: 'Opened' } }
                );
                setIsDialogOpen(true);
              }} color="black">
                <img src={questionMarkIcon} />
              </IconButton>
            </div>
            <span className='gift-review__content__right__fee__text'>
              +{formatCurrency(formattedFee)}
            </span>
          </div>
          <div className='gift-review__content__right__total'>
            <div className='gift-review__content__right__total__label'>
              {localized[`gifting.review.total`]}
            </div>
            <span className='gift-review__content__right__total__text'>
              {formatCurrency(selectedGiftAmount + (formattedFee), locale)}
            </span>
          </div>
          <div className='gift-review__content__right__message'>
            <div className='gift-review__content__right__message__label'>
              {localized[`gifting.review.message`]}
            </div>
            <div className='gift-review__content__right__message__textbox'>
              {checkoutMessage || '-'}
            </div>
          </div>
          <Button
            size='large'
            variant='tonal'
            className='gift-review__content__right__btn'
            isLoading={isPaymentLoading}
            onClick={submitCheckout}
          >
            {localized['gifting.review.button']}
          </Button>
          <ReviewDialog
            isOpen={isDialogOpen}
            localized={localized}
            onClose={() => {
              logComponentEvent(
                'GiftingReview',
                'ProcessingFeeDialog',
                { data: { action: 'Closed' } }
              );
              setIsDialogOpen(false);
            }}
          />
          <Footer />
        </div>
      </div>
    </div>
  );
}

GiftReview.propTypes = {
  locale: PropTypes.string,
  localized: PropTypes.object,
  beneficiary: PropTypes.object,
  selectedGiftAmount: PropTypes.number,
  totalProcessingFee: PropTypes.number,
  isPaymentLoading: PropTypes.bool,
  checkoutMessage: PropTypes.string,
  submitCheckout: PropTypes.func
};
