import { useEffect, useMemo, useState } from "react";
import { useGiftDetailsError } from "./useGiftDetailsError";
import { browserHistory } from "react-router";
import { getGiftOptions } from "../services/constants";
import { calculateProcessFee } from "services/stripe";
import { logComponentEvent } from "services/logging/componentEvent";

export default function useGiftDetails({
  localized,
  setAlertProps,
  flatProcessFee,
  percentageProcessFee
}) {
  const [selectedGiftOption, setSelectedGiftOption] = useState(0);
  const [selectedGiftAmount, setSelectedGiftAmount] = useState(0);
  const [checkoutMessage, setCheckoutMessage] = useState('');

  const {
    amountError,
    messageError,
    validateDetailsForm,
    updateAmountError,
    updateMessageError
  } = useGiftDetailsError();

  const giftOptions = getGiftOptions(localized, selectedGiftOption);

  // Flag used for hiding Alert banner on if amount is empty
  const isAmountEmptyMessage = amountError?.message === 'giftDetails.error.amountEmpty';

  const totalProcessingFee = useMemo(() => {
    if (!selectedGiftAmount || isNaN(selectedGiftAmount)) {
      return 0;
    }
    return calculateProcessFee(selectedGiftAmount, { flatProcessFee, percentageProcessFee });
  }, [selectedGiftAmount, flatProcessFee, percentageProcessFee]);

  const updateGiftOption = (input) => {
    if (input === 'custom') {
      setSelectedGiftAmount(0);
      setSelectedGiftOption(input);
    } else {
      setSelectedGiftOption(input);
      updateGiftAmount(input);
    };
  };

  // Validates and update gift amount
  const updateGiftAmount = (amount) => {
    const formattedAmount = Number(amount) || 0;
    logComponentEvent(
      'GiftingAmount',
      'AmountSelected',
      {
        data: {
          action: formattedAmount,
          amount: formattedAmount
        }
      }
    );

    // When switching from EN -> FR, we need to track if amount has changed...
    // to not cause unnecessary errors from being rendered.
    if (formattedAmount !== selectedGiftAmount) {
      updateAmountError(formattedAmount);
    }

    setSelectedGiftAmount(formattedAmount);
  };

  // Validates and update gift amount
  const updateMessage = (input) => {
    updateMessageError(input);
    setCheckoutMessage(input);
  };

  // Checkout after validation
  const onDetailsNext = () => {
    const {
      isAmountValid,
      isMessageValid
     } = validateDetailsForm(selectedGiftAmount, checkoutMessage);
     logComponentEvent('GiftingAmount', 'ContinueClicked', { data: { button: 'Continue' } });

    if (!isAmountValid && !isMessageValid) {
      setAlertProps({
        severity: 'error',
        message: 'giftDetails.error.allFieldsRequired'
      });
      updateAmountError(selectedGiftAmount);
    } else if (!isAmountValid) {
      updateAmountError(selectedGiftAmount);
    } else if (!isMessageValid) {
      updateMessageError(selectedGiftAmount);
    } else {
      browserHistory.push('/gift/compliance');
    }
  };

  return {
    selectedGiftAmount,
    checkoutMessage,
    giftOptions,
    amountError,
    messageError,
    selectedGiftOption,
    totalProcessingFee,
    isAmountEmptyMessage,
    onDetailsNext,
    updateMessage,
    updateGiftOption,
    updateGiftAmount,
    setSelectedGiftAmount,
    validateDetailsForm
  };
};
