import React, { useContext } from 'react';
import Link from 'components/common/Link';
import { LocalizationContext } from 'services/localization';
import EmbarkLogo from 'assets/icons/embark_white_tm.svg';
import FaceBookIcon from 'assets/icons/socialMedia/facebook_white.svg';
import InstagramIcon from 'assets/icons/socialMedia/instagram_white.svg';
import LinkedInIcon from 'assets/icons/socialMedia/linkedin_white.svg';
import YoutubeIcon from 'assets/icons/socialMedia/youtube_white.svg';
import { getCurrentYear } from 'services/dateTime';
import './styles/footer.scss';

export default function Footer() {
  const localized = useContext(LocalizationContext);

  return (
    <footer className="home-screen-footer">
      <div className="home-screen-footer__logo">
        <img src={EmbarkLogo} alt="Embark Logo" />
      </div>
      <div className="home-screen-footer__socials">
        <Link
          href="https://www.facebook.com/EmbarkCAN"
          opensInNewTab
          onClick={() => {
            logEventSocialLinks({ socialLink: 'Facebook' });
            logClickOnFacebook();
          }}
        >
          <img src={FaceBookIcon} alt="Facebook" />
        </Link>
        <Link
          href="https://www.youtube.com/@EmbarkCanada"
          opensInNewTab
          onClick={() => {
            logEventSocialLinks({ socialLink: 'YouTube' });
            logClickOnYouTube();
          }}
        >
          <img src={YoutubeIcon} alt="Youtube" />
        </Link>
        <Link
          href="https://www.linkedin.com/company/embarkstudentcorp"
          opensInNewTab
          onClick={() => {
            logEventSocialLinks({ socialLink: 'LinkedIn' });
            logClickOnLinkedIn();
          }}
        >
          <img src={LinkedInIcon} alt="Linkedin" />
        </Link>
        <Link
          href="https://www.instagram.com/embarkcanada/"
          opensInNewTab
          onClick={() => {
            logEventSocialLinks({ socialLink: 'Instagram' });
            logClickOnInstagram();
          }}
        >
          <img src={InstagramIcon} alt="Instagram" />
        </Link>
      </div>
      <div className="home-screen-footer__trademark">
        <div>{`© ${getCurrentYear()} ${localized['footer.embark'] || ''} ${localized['footer.rightsReserved'] || ''}`}</div>
        <div>{localized['footer.trademarkLine2'] || ''}</div>
      </div>
    </footer>
  );
}
