export const provinces = [
  'Alberta', 'British Columbia', 'Manitoba', 'Nova Scotia', 'New Brunswick',
  'Newfoundland and Labrador', 'Ontario', 'Prince Edward Island', 'Quebec',
  'Saskatchewan', 'Northwest Territories', 'Yukon', 'Nunavut'
];

export const provinceCodes = [
  'AB', 'BC', 'MB', 'NS', 'NB',
  'NL', 'ON', 'PE', 'QC', 'SK',
  'NT', 'YT', 'NU'
];

export const getLocalizedProvinceOptions = (localized, includeTerritories = false, includeCanadaWide = true) => {
  return [
    { label: localized['alberta'], value: 'AB' },
    { label: localized['britishColumbia'], value: 'BC' },
    { label: localized['manitoba'], value: 'MB' },
    { label: localized['newBrunswick'], value: 'NB' },
    { label: localized['newfoundlandAndLabrador'], value: 'NL' },
    ...(includeTerritories ? [{ label: localized['northwestTerritories'], value: 'NT' }] : []),
    { label: localized['novaScotia'], value: 'NS' },
    ...(includeTerritories ? [{ label: localized['nunavut'], value: 'NU' }] : []),
    { label: localized['ontario'], value: 'ON' },
    { label: localized['princeEdwardIsland'], value: 'PE' },
    { label: localized['quebec'], value: 'QC' },
    { label: localized['saskatchewan'], value: 'SK' },
    ...(includeTerritories ? [{ label: localized['yukon'], value: 'YT' }] : []),
    ...(includeCanadaWide ? [{ label: localized['schoolFilter.canadaWide'], value:  'CANADAWIDE' }] : [])
  ];
};

export const getProvinceNameFromCode = (code) => {
  switch (code) {
    case 'AB':
      return 'Alberta';
    case 'BC':
      return 'British Columbia';
    case 'MB':
      return 'Manitoba';
    case 'NB':
      return 'New Brunswick';
    case 'NL':
      return 'Newfoundland and Labrador';
    case 'NS':
      return 'Nova Scotia';
    case 'ON':
      return 'Ontario';
    case 'PE':
      return 'Prince Edward Island';
    case 'QC':
      return 'Quebec';
    case 'SK':
      return 'Saskatchewan';
    case 'NT':
      return 'Northwest Territories';
    case 'YT':
      return 'Yukon';
    case 'NU':
      return 'Nunavut';
    case 'CANADAWIDE':
      return 'Canada Wide';
    default:
      return '';
  }
};

// TODO: update both server and src when updating validatePostalCode
export function validatePostalCode(postalCode = '') {
  const condition = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
  return condition.test(postalCode);
};

export function validateAllowedProvince(province = '') {
  switch (province) {
    case 'QC':
      return true; // Allow onboarding for Quebec residents
    default:
      return true;
  }
};

// The first character of a postal code represents a province or territory,
// or a major sector entirely within a province
export const validateCorrespondingPostalCode = (province, postalCode) => {
  switch (province) {
    case 'AB':
      return /^[tT].*/.test(postalCode);
    case 'BC':
      return /^[vV].*/.test(postalCode);
    case 'MB':
      return /^[rR].*/.test(postalCode);
    case 'NS':
      return /^[bB].*/.test(postalCode);
    case 'NB':
      return /^[eE].*/.test(postalCode);
    case 'NL':
      return /^[aA].*/.test(postalCode);
    case 'ON':
      return /^[kKlLmMnNpP].*/.test(postalCode);
    case 'PE':
      return /^[cC].*/.test(postalCode);
    case 'QC':
      return /^[gGhHjJ].*/.test(postalCode);
    case 'SK':
      return /^[sS].*/.test(postalCode);
    case 'YT':
      return /^[yY].*/.test(postalCode);
    case 'NT':
    case 'NU':
      return /^[xX].*/.test(postalCode);
    default:
      return false;
  }
};

export const getProvinceFromPostalCode = (postalCode) => {
  if (!postalCode) return 'CANADAWIDE';
  const sanitizedPostalCode = postalCode.replace(/\s|-/g, '').toUpperCase();

  const provinceMap = {
    'AB': /^T\d/,
    'BC': /^V\d/,
    'PE': /^C\d/,
    'MB': /^R\d/,
    'NB': /^E\d/,
    'NS': /^B\d/,
    'ON': /^[KLMNP]\d/,
    'QC': /^[GHJ]\d/,
    'SK': /^S\d/,
    'NL': /^A\d/,
    'NU': /^(X0A|X0B|X0C)/,
    'NT': /^(X1A|X0E|X0G)/,
    'YT': /^Y\d/
  };

  for (const [province, pattern] of Object.entries(provinceMap)) {
    if (pattern.test(sanitizedPostalCode)) {
      return province;
    }
  }
  return 'CANADAWIDE';
};

export const countryCodeToLabelMap = {
  'CA': 'Canada',
  'US': 'United States'
};
