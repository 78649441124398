module.exports = {
  giftingComponentEvents: {
    'GiftingAbout': {
      'code':' GiftingAbout',
      'events': {
        'PageLoaded' : {
          'description':'Triggered when gifting about page is loaded',
          'eventHub': {
            'code': 'PageLoaded',
            'action': 'Gifting_Gifter_Welcome_page',
            'dataKeys': []
          },
          'ga': {
            'action':'GIFT - About - Page loaded'
          }
        },
        'ContinueClicked' : {
          'description': 'Triggered when the user clicks the "Continue" button.',
          'eventHub': {
            'code': 'ContinueClicked',
            'action': 'Gifting_Gifter_Welcome_page_Continue',
            'dataKeys': ['button']
          },
          'ga': {
            'action':'GIFT - About - Clicked continue'
          }
        }
      }
    },
    'GiftingAmount': {
      'code': 'GiftingAmount',
      'events': {
        'PageLoaded' : {
          'description': 'Triggered when gifting details page is loaded',
          'eventHub': {
            'code': 'PageLoaded',
            'action': 'Gifting_Gifter_Amount',
            'dataKeys': [
              'amount'
            ]
          },
          'ga': {
            'action':'GIFT - Amount - Page loaded'
          }
        },
        'BackClicked' : {
          'description': 'Triggered when the user clicks the "Back" button.',
          'eventHub': {
            'code': 'BackClicked',
            'action': 'Gifting_Gifter_Amount_Back',
            'dataKeys': ['button']
          },
          'ga': {
            'action':'GIFT - Amount - Clicked back'
          }
        },
        'AmountSelected' : {
          'description': 'Triggered when an amount is selected',
          'eventHub': {
            'code': 'AmountSelected',
            'action': 'Gifting_Gifter_Amount_Selected',
            'dataKeys': ['amount']
          },
          'ga': {
            'action':'GIFT - Amount - Selected $[$.action]'
          }
        },
        'ContinueClicked' : {
          'description': 'Triggered when the user clicks the "Continue" button.',
          'eventHub': {
            'code': 'ContinueClicked',
            'action': 'Gifting_Gifter_Amount_Continue',
            'dataKeys': ['button']
          },
          'ga': {
            'action':'GIFT - Amount - Clicked continue'
          }
        }
      }
    },
    'GiftingKYC': {
      'code': 'GiftingKYC',
      'events': {
        'PageLoaded' : {
          'description': 'Triggered when KYC (relationship) is loaded',
          'eventHub': {
            'code': 'PageLoaded',
            'action': 'Gifting_Gifter_KYC',
            'dataKeys': []
          },
          'ga': {
            'action':'GIFT - KYC - Page loaded'
          }
        },
        'BackClicked' : {
          'description': 'Triggered when the user clicks the "Back" button.',
          'eventHub': {
            'code': 'BackClicked',
            'action': 'Gifting_Gifter_KYC_Back',
            'dataKeys': ['button']
          },
          'ga': {
            'action':'GIFT - KYC - Clicked back'
          }
        },
        'RelationshipSelected' : {
          'description': 'Triggered when KYC (relationship) is selected',
          'eventHub': {
            'code': 'RelationshipSelected',
            'action': 'Gifting_Gifter_KYC_Relationship',
            'dataKeys': ['relationship']
          },
          'ga': {
            'action':'GIFT - KYC - Selected relationship [$.action]'
          }
        },
        'ContinueClicked' : {
          'description': 'Triggered when the user clicks the "Continue" button.',
          'eventHub': {
            'code': 'ContinueClicked',
            'action': 'Gifting_Gifter_KYC_Continue',
            'dataKeys': ['button']
          },
          'ga': {
            'action':'GIFT - KYC - Continue clicked'
          }
        }
      }
    },
    'GiftingReview': {
      'code': 'GiftingReview',
      'events': {
        'PageLoaded' : {
          'description': 'Triggered when gifting review page is loaded',
          'eventHub': {
            'code': 'PageLoaded',
            'action': 'Gifting_Gifter_Review',
            'dataKeys': []
          },
          'ga': {
            'action':'GIFT - Review - Page loaded'
          }
        },
        'BackClicked' : {
          'description': 'Triggered when the user clicks the "Back" button.',
          'eventHub': {
            'code': 'BackClicked',
            'action': 'Gifting_Gifter_Review_Back',
            'dataKeys': ['button']
          },
          'ga': {
            'action':'GIFT - Review - Clicked back'
          }
        },
        'ProcessingFeeDialog' : {
          'description': 'Triggered when the processing fee dialog is opened or closed.',
          'eventHub': {
            'code': 'ProcessingFeeDialog',
            'action': 'Gifting_Gifter_Review_Processing_Fee_[$.action]',
            'dataKeys': ['action']
          },
          'ga': {
            'action':'GIFT - Review - Processing fee dialog [$.action]'
          }
        },
        'Pay' : {
          'description': 'Triggered when the user clicks the "Pay and send gift" button.',
          'eventHub': {
            'code': 'Pay',
            'action': 'Gifting_Gifter_Review_Pay',
            'dataKeys': ['button']
          },
          'ga': {
            'action':'GIFT - Review - Pay and send gift'
          }
        }
      }
    },
    'GiftingSuccess': {
      'code': 'GiftingSuccess',
      'events': {
        'PageLoaded' : {
          'description': 'Triggered when Success page is loaded',
          'eventHub': {
            'code': 'PageLoaded',
            'action': 'Gifting_Gifter_Success',
            'dataKeys': []
          },
          'ga': {
            'action':'GIFT - Success - Page loaded'
          }
        },
        'DoneClicked' : {
          'description': 'Triggered when the user clicks the "Done" button.',
          'eventHub': {
            'code': 'DoneClicked',
            'action': 'Gifting_Gifter_Success_Done',
            'dataKeys': ['button']
          },
          'ga': {
            'action':'GIFT - Success - Clicked done'
          }
        }
      }
    }
  }
};
