import {
  highlight,
  highlight100,
  highlight99,
  highlight95,
  highlight90,
  highlight80,
  highlight70,
  highlight60,
  highlight50,
  highlight40,
  highlight30,
  highlight20,
  highlight10,
  highlight0
} from 'styles/colors.scss';

export default {
  100: highlight100,
  99: highlight99,
  95: highlight95,
  90: highlight90,
  80: highlight80,
  70: highlight70,
  60: highlight60,
  50: highlight50,
  40: highlight40,
  30: highlight30,
  20: highlight20,
  10: highlight10,
  0: highlight0,
  main: highlight,
  light: highlight40,
  dark: highlight80,
  contrastText: highlight100
};
