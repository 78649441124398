export const validateEmailFormat = (email) => {
  const regEx = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
  return regEx.test(email);
};

export const validateMissingEmail = (email) => {
  return email;
};

export const validateEmailLength = (email) => {
  return email.length <= 40;
};
