import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Button from "components/common/Button";
import Dropdown from "components/common/Dropdown";
import AccountToolbar from "components/common/AccountToolbar";
import IconBanner from "components/common/IconBanner";
import ProfileImage from "components/common/ProfileImage";
import Alert from "components/common/Alert";
import ShieldImg from 'assets/images/shield.svg';
import { browserHistory } from "react-router";
import { getRelationshipOptions } from "./services";
import { logComponentEvent } from "services/logging/componentEvent";
import './styles/giftCompliance.scss';

function GiftCompliance({
  localized,
  beneficiary,
  relationship,
  relationshipError,
  updateRelationship,
  onComplianceNext
}) {
  useEffect(() => {
    logComponentEvent('GiftingKYC', 'PageLoaded');
  }, []);

  return (
    <div className='gift-compliance'>
      <AccountToolbar
        title={localized['giftCompliance.title'] || ''}
        onBackClick={() => {
          logComponentEvent(
            'GiftingKYC',
            'BackClicked',
            { data: { button: 'Back' } }
          );
          browserHistory.push('/gift/details');
        }}
        className='gift-compliance__toolbar'
        showBackButton
      />
      <IconBanner
        className='gift-compliance__banner'
        title={localized['giftCompliance.banner.title'] || ''}
        description={localized['giftCompliance.banner.description'] || ''}
        imgSrc={ShieldImg}
        imgAlt={localized['img.alt.shieldIcon']}
      />
      <ProfileImage
        name={{
          first: beneficiary?.firstName,
          last: beneficiary.lastInitial
        }}
        profileImageSrc={beneficiary?.profilePictureUrl}
        isUploadPictureEnabled={false}
      />
      <div className='gift-compliance__content'>
        <section className='gift-compliance__content__relationship'>
          <h2 className='gift-compliance__content__relationship__heading'>
            {localized['giftCompliance.relationship.heading'] || ''}
          </h2>
          {relationshipError && (
            <Alert
              title={localized[relationshipError?.message]}
              severity={relationshipError?.severity}
              className='gift-compliance__content__relationship__alert'
            />
          )}
          <Dropdown
            value={relationship}
            label={localized['giftCompliance.relationship.dropdown.label'] || ''}
            onSelect={(e) => updateRelationship(e.target.value)}
            items={getRelationshipOptions(localized)}
            error={localized[relationshipError?.message]}
            showErrorMessage
          />
        </section>
      </div>
      <Button
        size='large'
        variant='tonal'
        className='gift-compliance__btn'
        onClick={onComplianceNext}
      >
        {localized['continue'] || ''}
      </Button>
    </div>
  );
}

GiftCompliance.propTypes = {
  localized: PropTypes.object,
  beneficiary: PropTypes.object,
  relationship: PropTypes.string,
  relationshipError: PropTypes.object,
  updateRelationship: PropTypes.func,
  onComplianceNext: PropTypes.func
};

export default GiftCompliance;
