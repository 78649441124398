const { giftingComponentEvents } = require('./componentPath/gifting');
const { GiftingAbout, GiftingAmount, GiftingKYC, GiftingReview, GiftingSuccess } = giftingComponentEvents;

module.exports = {
  componentEvents:
  {
    '<ComponentName>':{
      'code':'<Code used for Component',
      'events':{
        '<eventName>':{
          'discription':'<Optional description to define the event>',
          'eventHub':{
            'code':'<eventHub Code for the event>',
            'action':'<eventHub action text with [$.<optional.data.path>] which will get replace at runtime>',
            'dataKeys': ['optional', 'list', 'of', 'data', 'elements', 'to', 'be', 'sent', 'along', 'with', 'evnet']
          },
          'ga':{
            'action':'<Action text for Google Analytics with [$.<optional.data.path>] which will get replace at runtime'
          }
        }
      }
    },
    GiftingAbout,
    GiftingAmount,
    GiftingKYC,
    GiftingReview,
    GiftingSuccess
  }
};
