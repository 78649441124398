import { validateDate, getAge } from 'services/dateTime';
import { isAfter, isBefore, isSameDay } from 'date-fns';
import { useInputState } from './useInputState';
import moment from 'moment';

export function useDateOfBirthInputState(options) {
  const { cantBeUnder18, isBeneficiary, ...inputStateOptions } = options || {};
  const validations = [
    {
      validate: value => !!value,
      errorMessage: isBeneficiary ? 'dateOfBeneBirthRequiredError' : 'dateOfBirthRequiredError'
    },
    {
      validate: validateDate,
      errorMessage: 'dateFormatError'
    },
    {
      validate: value => isAfter(new Date(value), new Date('1899-12-31T23:59:59')),
      errorMessage: 'dateFormatError'
    },
    {
      validate: value => isBefore(new Date(value), new Date()),
      errorMessage: 'invalidBirthDateError'
    },
    {
      validate: value => cantBeUnder18 ? getAge(value) >= 18 : true,
      errorMessage: 'ageUnderError'
    },
    {
      validate: value => isBeneficiary ? getAge(value) < 21 : true,
      errorMessage: 'beneficiaryAgeError'
    },
    {
      validate: value => {
        const date = value.getDate();
        const month = value.getMonth() + 1;
        const year = value.getFullYear();
        if (isBeneficiary) {
          return moment(`${date}/${month}/${year}`, 'DD-MM-YYYY').isValid();
        } else {
          return true;
        }
      },
      errorMessage: 'invalidDate'
    }
  ];

  return useInputState({
    initialValue: null,
    validations,
    checkIsChanged: (initialValue, value) => {
      if (!initialValue && !value) {
        return false;
      }

      if (!initialValue && value) {
        return true;
      }

      if (initialValue && !value) {
        return true;
      }

      return !isSameDay(initialValue, value);
    },
    ...(inputStateOptions || {})
  });
}
