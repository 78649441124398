import React, { useContext } from 'react';
import { LocalizationContext } from 'services/localization';
import { getCurrentYear } from 'services/dateTime';
import './styles/footer.scss';

export default function Footer() {
  const localized = useContext(LocalizationContext);

  return (
    <div className="footer">
      <div className="footer__content">
        <div className="footer__content__description">
          <div>{localized['home.footerCopyrightSymbol'] || ''} {getCurrentYear()} {localized['embark'] || ''}. {localized['home.footerAllRightsReserved'] || ''}</div>
          <div>{localized['home.footerTrademarkText'] || ''}</div>
        </div>
      </div>
    </div>
  );
}
