import { useInputState } from './useInputState';
import { vaildateMaxDigits } from 'services/maxDigits';

export function useAmountInputState(options) {
  const { errorMessage = 'amountError', validations, initialValue, isRequired = true, canBeZero = false, ...otherOptions } = options || {};
  return useInputState({
    initialValue: initialValue || '',
    validations: [
      {
        validate: value => !isRequired || (value && !!value),
        errorMessage
      },
      {
        validate: value => canBeZero ? Number(value) >= 0 : Number(value) > 0,
        errorMessage
      },
      {
        validate: value => vaildateMaxDigits(value),
        errorMessage: 'maxDigitError'
      },
      ...(validations || [])
    ],
    ...(otherOptions || {})
  });
}
