import { useInputState } from './useInputState';
import { validatePhone } from 'services/phone';

const validations = [
  {
    validate: value => value && value.trim && !!value.trim(),
    errorMessage: 'phoneNumberRequiredError'
  },
  {
    validate: value => validatePhone(value.trim()),
    errorMessage: 'phoneNumberFormatError'
  }
];

export function usePhoneInputState(options) {
  return useInputState({
    initialValue: '',
    validations,
    ...(options || {})
  });
}
