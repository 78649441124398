/**
 * Calculates Stripe's total processing fee

 * @param {object} [app] - App object returned from server
 * @param {number} [amount=0] - The raw amount to calculate processing fee.
 * @returns {number} The total processing fee of Stripe based on amount.

 * @example
 * calculateProcessFee(100, { percentageProcessFee: 0.0226, flatProcessFee: 33}); // 259
*/

module.exports = calculateProcessFee = (amount = 0, options) => {
  const { percentageProcessFee = 0, flatProcessFee = 0 } = options || {};

  if (!amount || isNaN(amount)) {
    return 0;
  }

  // Note: Multiply by 100 to match unitAmount on Stripe's reponse
  return Math.ceil(((amount * 100) * percentageProcessFee) + flatProcessFee);
};
