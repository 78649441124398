export const getSlugByPathName = (pathname) => {
  const segments = pathname.split('/');
  return segments[segments.length - 1];
};

export function isRedirectAllowed(nextRoute, routeNames = allowedRedirectRoutes) {
  if (routeNames.length <= 0) {
    return true;
  }
  return routeNames
  .filter(routeName => {
    if (nextRoute) {
      const nextRouteWithoutParam = (nextRoute.split('/?')[0] || '').split('?')[0];

      return nextRouteWithoutParam.includes(routeName);
    }
    return false;
  })
  .length > 0;
}
