import { useState, useEffect } from 'react';

export function useAlertProps(options = {}) {
  const [alertProps, setAlertProps] = useState(null);
  const [shouldShowAlert, setShouldShowAlert] = useState(options.shouldShowAlert || false);

  useEffect(() => {
    if (!shouldShowAlert) {
      setAlertProps(null);
    }
  }, [shouldShowAlert, alertProps]);

  return {
    alertProps,
    setAlertProps,
    shouldShowAlert,
    setShouldShowAlert
  };
}
