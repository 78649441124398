export const AMOUNT_LIMIT = {
  MINIMUM: 25,
  MAXIMUM: 2500
};

export const AMOUNT_DECIMAL_OFFSET = 100;

export const MAXIMUM_CHARACTER_COUNT = 1000;

export const getGiftOptions = (localized, selectedOption) => [
  {
    label: '$25',
    value: 25
  },
  {
    label: '$50',
    value: 50
  },
  {
    label: '$100',
    value: 100
  },
  {
    label: '$250',
    value: 250
  },
  {
    label: '$500',
    value: 500
  },
  ...(selectedOption !== 'custom' ? [
    {
      label: localized['giftDetails.options.custom'],
      value: 'custom'
    }
  ] : [])
];
