import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import giftingLogo from 'assets/logo/embark_gifting_logo.svg';
import brandmarkLogo from 'assets/logo/embark_brandmark_standard.svg';
import ProfileImage from 'components/common/ProfileImage';
import Button from 'components/common/Button';
import { getGiftAboutText, getGiftAboutList } from './services';
import RedirectIcon from 'assets/icons/redirect_icon.svg';
import Link from 'components/common/Link';
import { logComponentEvent } from 'services/logging/componentEvent';
import './styles/giftAbout.scss';

export default function GiftAbout({ localized, beneficiary, onGiftNowClick }) {
  const termsLink = __GIFTING__GIFTER__TERMS__EN__;
  const { profilePictureUrl, firstName, lastInitial } = beneficiary || {};
  const { header, body } = getGiftAboutText(localized, firstName);
  const listItems = getGiftAboutList(localized);

  useEffect(() => {
    logComponentEvent('GiftingAbout', 'PageLoaded');
  }, []);

  return (
    <div className='send-a-gift-about'>
      <div className='send-a-gift-about__content'>
      <div className='send-a-gift-about__content__logo'><img src={giftingLogo}/></div>
      <div className='send-a-gift-about__content__img'>
        <ProfileImage
          name={{
            first: firstName,
            last: lastInitial
          }}
          profileImageSrc={profilePictureUrl}
          isUploadPictureEnabled={false}
        />
        <img className='send-a-gift-about__content__img__logo' src={brandmarkLogo} />
      </div>
      <div className='send-a-gift-about__content__header'>{header}</div>
      <div className='send-a-gift-about__content__body'>{body}</div>
      <Button
        onClick={onGiftNowClick}
        className='send-a-gift-about__content__gift-btn'
        variant='tonal'
      >
        {localized['gifting.about.button']}
      </Button>
      </div>
      <div className='send-a-gift-about__list'>
        <div className='send-a-gift-about__list__header'>{localized['gifting.list.header']}</div>
        <div className='send-a-gift-about__list__list'>
          {listItems.map((item) => {
            return (
              <div key={item.id} className='send-a-gift-about__list__list__item'>
                <div>
                  <img alt={item.alt} src={item.image} />
                </div>
                <div className='send-a-gift-about__list__list__item__text'>
                  <div className='send-a-gift-about__list__list__item__text__header'>{item.header}</div>
                  <div className='send-a-gift-about__list__list__item__text__body'>{item.body}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <Button
        size='large'
        onClick={onGiftNowClick}
        className='send-a-gift-about__content__gift-btn--bottom'
        variant='tonal'
      >
        {localized['gifting.about.button']}
      </Button>
      <div className='send-a-gift-about__terms'>
        <span className='send-a-gift-about__terms__body'>
          {localized['gifting.about.terms.body']}
        </span>
        <span>
          <Link className='send-a-gift-about__terms__link' href={termsLink} opensInNewTab>
            {localized['gifting.about.terms.link']}
            <img src={RedirectIcon} alt={localized['gifting.about.terms.alt']}/>
          </Link>
        </span>
      </div>
    </div>
  );
}

GiftAbout.propTypes = {
  localized: PropTypes.object,
  beneficiary: PropTypes.object,
  onGiftNowClick: PropTypes.func
};
