export const getRelationshipOptions = (localized) => [
  {
    label: localized['giftCompliance.relationship.dropdown.grandparent'] || '',
    value: 'grandparent'
  },
  {
    label: localized['giftCompliance.relationship.dropdown.aunt'] || '',
    value: 'aunt'
  },
  {
    label: localized['giftCompliance.relationship.dropdown.uncle'] || '',
    value: 'uncle'
  },
  {
    label: localized['giftCompliance.relationship.dropdown.sibling'] || '',
    value: 'sibling'
  },
  {
    label: localized['giftCompliance.relationship.dropdown.parent'] || '',
    value: 'parent'
  },
  {
    label: localized['giftCompliance.relationship.dropdown.notRelated'] || '',
    value: 'notRelated'
  },
  {
    label: localized['giftCompliance.relationship.dropdown.other'] || '',
    value: 'other'
  }
];
