import lockImage from 'components/Gift/assets/lock.svg';
import moneyImage from 'components/Gift/assets/money.svg';
import profileImage from 'components/Gift/assets/profile.svg';

export const getGiftAboutText = (localized, firstName) => {
    const possesiveFirstName = firstName && firstName + `${firstName.slice(-1) === 's' ? `'` : `'s`}`;
    return {
      header :`${localized['gifting.about.headerOne']} ${possesiveFirstName} ${localized['gifting.about.headerTwo']}`,
      body: `${localized['gifting.about.bodyOne']} ${possesiveFirstName} ${localized['gifting.about.bodyTwo']}`
    };
};

export const getGiftAboutList = (localized) => {
  return [
    {
      id: 'safe',
      header: localized['gifting.list.one.header'],
      body: localized['gifting.list.one.body'],
      image: lockImage,
      alt: 'padlock'
    },
    {
      id: 'money',
      header: localized['gifting.list.two.header'],
      body: localized['gifting.list.two.body'],
      image: moneyImage,
      alt: 'dollar bills'
    },
    {
      id: 'profile',
      header: localized['gifting.list.three.header'],
      body: localized['gifting.list.three.body'],
      image: profileImage,
      alt: 'profile image'
    }
  ];
};
