export function getInitials(firstName, lastName) {
  const firstNameLetter = firstName && firstName.charAt(0).toUpperCase() || '';
  const lastNameLetter = lastName && lastName.charAt(0).toUpperCase() || '';
  return `${firstNameLetter}${lastNameLetter}`;
};

export function getFullName(firstName, lastName, middleName) {
  if (firstName && lastName) {
    return firstName + (middleName ? ` ${middleName}` : '') + ` ${lastName}`;
  }
  return '';
}

export function getPossessiveFirstName(firstName, locale) {
  if (locale === 'en-US') {
    return firstName && firstName.slice(-1) === 's' ? `${firstName}'` : `${firstName}'s`;
  }
  return firstName;
};

export const validateNameFormat = (name) => {
  if (typeof name !== 'string') {
    return false;
  }

  const regEx = /^(['.\-a-zA-Z ÀàÁáÂâÇçÈÉÊèéêÔô]{0,50})$/;
  return regEx.test(name);
};

export const validateNameLength = (name, maxLength = 20) => {
  return name.length <= maxLength;
};

export const validateMiddleNameLength = (name, maxLength = 15) => {
  return name.length <= maxLength;
};

export const trimSpaces = (name) => {
  const regexPattern = /\s+/g;
  return name && name.replace(regexPattern, ' ').trim();
};
