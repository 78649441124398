import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog as MUIDialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';
import './styles/dialog.scss';

export default function Dialog({
  isOpen = false,
  titleIcon,
  renderTitle,
  className = '',
  titleClassName = '',
  contentClassName = '',
  actionsClassName = '',
  renderActions,
  renderContent,
  dialogContentProps,
  fullScreen,
  ...dialogProps
}) {
  return (
    <MUIDialog
      className={`dialog ${fullScreen ? 'dialog--full-screen' : ''} ${className || ''}`}
      open={isOpen}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-content"
      fullScreen={fullScreen}
      {...(dialogProps || {})}
    >
      {renderTitle && (
        <DialogTitle id="dialog-title" className={`dialog__title ${titleClassName || ''}`}>
          {titleIcon && titleIcon}
          {renderTitle()}
        </DialogTitle>
      )}
      {renderContent && (
        <DialogContent id="dialog-content" className={`dialog__content ${contentClassName || ''}`} {...(dialogContentProps || {})}>
          {renderContent()}
        </DialogContent>
      )}
      {renderActions && (
        <DialogActions className={`dialog__actions ${actionsClassName || ''}`}>
          {renderActions()}
        </DialogActions>
      )}
    </MUIDialog>
  );
}

Dialog.propTypes = {
  isOpen: PropTypes.bool,
  fullScreen: PropTypes.bool,
  location: PropTypes.object,
  account: PropTypes.object,
  unsavedChangesInstance: PropTypes.object,
  isNavigationMenuOpen: PropTypes.bool,
  setIsNavigationMenuOpen: PropTypes.func,
  renderActions: PropTypes.func,
  renderContent: PropTypes.func,
  titleIcon: PropTypes.element,
  renderTitle: PropTypes.func,
  titleClassName: PropTypes.string,
  contentClassName: PropTypes.string,
  actionsClassName: PropTypes.string,
  className: PropTypes.string,
  dialogContentProps: PropTypes.object
};
