import React from 'react';
import PropTypes from 'prop-types';
import Chip from 'components/common/Chip';
import './styles/chipList.scss';

const variantPropMap = {
  primary: {
    labelClassName: 'chip-list__label--primary'
  }
};

export default function ChipList({
  label,
  variant = '',
  options,
  selectedValue,
  onChange,
  error,
  showErrorMessage = true,
  localized
}) {
  const { labelClassName } = variant && variantPropMap[variant] || {};
  function renderChip(option, index) {
    const { label, value } = option || {};
    return (
      <Chip
        className="chip-list__items__item"
        key={`${value}-${index}`}
        label={label}
        selected={!!selectedValue && value === selectedValue}
        error={localized[error]}
        onClick={e => onChange(value)}
      />
    );
  }

  return (
    <div className="chip-list">
      {label && (
        <div className={`chip-list__label${labelClassName ? ` ${labelClassName}` : ''}`}>
          {label}
        </div>
      )}
      <div className="chip-list__items">
        {options && options.length && options.map(renderChip)}
      </div>
      {error && showErrorMessage && (
        <div className="chip-list__error">
          {localized[error]}
        </div>
      )}
    </div>
  );
}

ChipList.propTypes = {
  label: PropTypes.string,
  variant: PropTypes.string,
  options: PropTypes.array,
  selectedValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  onChange: PropTypes.func,
  error: PropTypes.string,
  showErrorMessage: PropTypes.bool,
  localized: PropTypes.object
};
