import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Close as CloseIcon } from '@mui/icons-material';
import IconButton from 'components/common/IconButton';
import Alert from 'components/common/Alert';
import Button from 'components/common/Button';
import { getInitials, getPossessiveFirstName } from 'services/name';
import { parseErrorMessage } from 'services/webApi';
import LoadingSpinner from 'components/common/LoadingSpinner';
import useImageChangeConfirm from './hooks/useImageChangeConfirm';
import './styles/imageChangeConfirm.scss';

export default function ImageChangeConfirm({
  name,
  locale,
  localized,
  onClose,
  profileImageSrc,
  isLoading,
  setIsLoading,
  setIsEditing,
  removePicture,
  isPictureDeleted,
  setIsPictureDeleted,
  profilePictureBlobId
}) {
  const {
    shouldConfirm,
    handleClose,
    setShouldConfirm,
    handleRemovePhoto,
    handleChangeClick,
    error
  } = useImageChangeConfirm({
    onClose,
    isLoading,
    setIsLoading,
    setIsEditing,
    removePicture,
    isPictureDeleted,
    setIsPictureDeleted,
    profilePictureBlobId
  });

  function renderButtons() {
    return shouldConfirm
      ? (
        <Fragment>
          <div className="image-change-confirm__content__buttons__button">
            <Button size="medium" variant="tonal" onClick={() => handleClose()}>
              {localized['noKeep']}
            </Button>
          </div>
          <div className="image-change-confirm__content__buttons__button">
            <Button size="medium" variant="text" onClick={() => handleRemovePhoto()}>
              {localized['yesRemove']}
            </Button>
          </div>
        </Fragment>
      ) : (
        <Fragment>
          <div className="image-change-confirm__content__buttons__button">
            <Button size="medium" variant="tonal" onClick={() => handleChangeClick()}>
              {localized['change']}
            </Button>
          </div>
          <div className="image-change-confirm__content__buttons__button">
            <Button size="medium" variant="text" onClick={() => { isPictureDeleted ? onClose() : setShouldConfirm(true) }}>
              {isPictureDeleted ? localized['cancel'] : localized['remove']}
            </Button>
          </div>
        </Fragment>
      );
  }
  return (
    <div className="image-change-confirm">
      <div className="image-change-confirm__close">
        <IconButton
          variant="filled"
          className="image-change-confirm__close__button"
          aria-label={localized['close']}
          onClick={() => handleClose()}
        >
          <CloseIcon />
        </IconButton>
      </div>
      <div className="image-change-confirm__content">
        {isPictureDeleted ? (
          <div className="image-change-confirm__content__headline">
            {localized['imageChangeConfirm.successUpdate']}
          </div>
        ) : (
          <div className="image-change-confirm__content__headline">
            {shouldConfirm ? localized['confirmRemove'] : localized['imageChangeConfirm.edit']}
          </div>
        )}
        {shouldConfirm && (
          <div className="image-change-confirm__content__description">
            {localized['imageChangeConfirm.confirmDescription1'] + getPossessiveFirstName(name.first || '', locale) + localized['imageChangeConfirm.confirmDescription2']}
          </div>
        )}
        {isPictureDeleted && (
          <div className="image-change-confirm__content__description">
            {localized['imageChangeConfirm.successDeleteDescription']}
          </div>
        )}
        {error && (
          <div className="image-change-confirm__content">
            <Alert
              severity="error"
              showBorder
              className="image-change-confirm__content__error"
            >
              <div className="image-change-confirm__content__error__text">
                {parseErrorMessage(error, locale)}
              </div>
            </Alert>
          </div>
        )}
        {(profileImageSrc && !isPictureDeleted) && (
          <div className="image-change-confirm__content__image">
            <img
              src={profileImageSrc}
              alt={getInitials(name.first || '', name.last || '')}
            />
          </div>
        )}
        <div className='image-change-confirm__content__buttons'>
          {isLoading && (
            <Fragment>
              <LoadingSpinner />
              <div className='image-change-confirm__content__buttons__deleting'>{localized['imageChangeConfirm.deletingLoading']}</div>
            </Fragment>
          )}
          {!isLoading && renderButtons()}
        </div>

      </div>
    </div>
  );
};

ImageChangeConfirm.propTypes = {
  name: PropTypes.shape({
    first: PropTypes.string,
    last: PropTypes.string
  }),
  locale: PropTypes.string,
  localized: PropTypes.object,
  onClose: PropTypes.func,
  profileImageSrc: PropTypes.string,
  isLoading: PropTypes.bool,
  setIsLoading: PropTypes.func,
  setIsEditing: PropTypes.func,
  removePicture: PropTypes.func,
  isPictureDeleted: PropTypes.bool,
  setIsPictureDeleted: PropTypes.func,
  profilePictureBlobId: PropTypes.string
};
