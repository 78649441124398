import { useState, useEffect } from 'react';

// TODO: Add other class names and User Agent detection if needed
const IS_APPLE_CLASS_NAME = 'is-apple';
const IS_ANDROID_CLASS_NAME = 'is-android';
const IS_CHROMIUM_CLASS_NAME = 'is-chromium';
const IS_APPLE_MOBILE_CLASS_NAME = 'is-ios';
const IS_APPLE_MAC_CLASS_NAME = 'is-mac';
const IS_APPLE_IPAD_CLASS_NAME = 'is-ipad';
const IS_SAFARI_CLASS_NAME = 'is-safari';
const IS_MOBILE_SAFARI_CLASS_NAME = 'is-mobile-safari';
const IS_MOBILE_CLASS_NAME = 'is-mobile';

const ALL_CLASSES = [
  IS_APPLE_CLASS_NAME,
  IS_ANDROID_CLASS_NAME,
  IS_CHROMIUM_CLASS_NAME,
  IS_APPLE_MOBILE_CLASS_NAME,
  IS_APPLE_MAC_CLASS_NAME,
  IS_APPLE_IPAD_CLASS_NAME,
  IS_SAFARI_CLASS_NAME,
  IS_MOBILE_SAFARI_CLASS_NAME,
  IS_MOBILE_CLASS_NAME
];

  // experimental feature userAgentData as a fallback to detect Apple mobile or simulator
  function isDeviceiOS() {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.userAgentData) ||
      (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
  };

/**
 * A helper function to detect the users device. Looking at the both the window and the Navigator.userAgent object
* @returns {{
 *   IS_APPLE: boolean,
 *   IS_ANDROID: boolean,
 *   IS_CHROMIUM: boolean,
 *   IS_APPLE_MOBILE: boolean,
 *   IS_SAFARI: boolean,
 *   IS_MOBILE_SAFARI: boolean,
 *   IS_MOBILE: boolean,
 *   IS_APPLE_IPAD: boolean
 * }} An object containing boolean flags indicating various properties of the user agent.
 */
function detectUserAgent() {
    const context = typeof window !== 'undefined' ? window : self;
    const USER_AGENT = navigator ? navigator?.userAgent : null;
    if (!USER_AGENT || !context) return;

    const IS_APPLE = /OS X|iPhone|iPad|iOS/i.test(USER_AGENT);
    const IS_ANDROID = /android/i.test(USER_AGENT);
    const IS_CHROMIUM = /Chrome|Chromium/.test(USER_AGENT);

    // https://stackoverflow.com/a/58065241
    const IS_APPLE_MOBILE =
      (
        /iPad|iPhone|iPod/.test(navigator?.platform ?? '') ||
          (navigator?.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
      ) &&
      !context?.MSStream ||
      isDeviceiOS();

    const IS_APPLE_IPAD =
      /Mac/i.test(USER_AGENT) &&
      !/iPhone/i.test(USER_AGENT) &&
      navigator?.maxTouchPoints &&
      navigator?.maxTouchPoints > 2;

    const IS_SAFARI =
      'safari' in context ||
      !('chrome' in context) ||
      (USER_AGENT &&
        (
          /\b(iPad|iPhone|iPod)\b/.test(USER_AGENT) ||
          (
            USER_AGENT.includes('Safari') &&
            !USER_AGENT.includes('Chrome')
          ))
        );

    const IS_MOBILE_SAFARI = IS_SAFARI && IS_APPLE_MOBILE;

    const IS_MOBILE = (navigator?.maxTouchPoints === undefined || navigator?.maxTouchPoints > 0) &&
      /iOS|iPhone OS|Android|BlackBerry|BB10|Series ?[64]0|J2ME|MIDP|opera mini|opera mobi|mobi.+Gecko|Windows Phone/i.test(USER_AGENT);

      return {
        IS_APPLE,
        IS_ANDROID,
        IS_CHROMIUM,
        IS_APPLE_MOBILE,
        IS_SAFARI,
        IS_MOBILE_SAFARI,
        IS_MOBILE,
        IS_APPLE_IPAD
      };
  }

/**
 * A hook to detect the user agent and apply corresponding class names to a target DOM node or document root (html) element.
 * @param {HTMLElement} [targetNode] - Optional target DOM node to apply classes. Defaults to document.documentElement if not provided.
 * @returns {Object} An object containing the user agent information.
 */
function useUserAgent(targetNode) {
  const [userAgent, setUserAgent] = useState({
    IS_APPLE: false,
    IS_ANDROID: false,
    IS_CHROMIUM: false,
    IS_APPLE_MOBILE: false,
    IS_SAFARI: false,
    IS_MOBILE_SAFARI: false,
    IS_MOBILE: false,
    IS_APPLE_IPAD: false
  });

  useEffect(() => {
    const {
      IS_APPLE,
      IS_ANDROID,
      IS_CHROMIUM,
      IS_APPLE_MOBILE,
      IS_SAFARI,
      IS_MOBILE,
      IS_APPLE_IPAD
    } = detectUserAgent();

    setUserAgent({
      IS_APPLE,
      IS_ANDROID,
      IS_CHROMIUM,
      IS_APPLE_MOBILE,
      IS_SAFARI,
      IS_MOBILE,
      IS_APPLE_IPAD
    });

    const target = typeof document !== 'undefined'
      ? ((targetNode && targetNode.current) ?? document.documentElement) : null;

    if (!target) return;

    if (IS_CHROMIUM) {
      target.classList.add(IS_CHROMIUM_CLASS_NAME);
    }

    if (IS_MOBILE) {
      target.classList.add(IS_MOBILE_CLASS_NAME);
    }

    if (IS_APPLE) {
      if (IS_SAFARI) {
        target.classList.add(IS_SAFARI_CLASS_NAME);
      }

    if (IS_APPLE_MOBILE) {
        target.classList.add(IS_APPLE_MOBILE_CLASS_NAME);
      } else {
        target.classList.add(IS_APPLE_MAC_CLASS_NAME);
      }

    if (IS_APPLE_IPAD) {
      target.classList.add(IS_APPLE_IPAD_CLASS_NAME);
    }
    } else if (IS_ANDROID) {
      target.classList.add(IS_ANDROID_CLASS_NAME);
    }

  return () => target.classList.remove(...ALL_CLASSES);
  }, [targetNode]);

  return {
    userAgent
  };
}
export { useUserAgent };
