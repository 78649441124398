import primary from './primary';
import secondary from './secondary';
import tertiary from './tertiary';
import error from './error';
import neutral from './neutral';
import neutralVariant from './neutralVariant';
import highlight from './highlight';
import success from './success';
import accent from './accent';
import info from './info';

export default {
  primary,
  secondary,
  tertiary,
  error,
  neutral,
  neutralVariant,
  highlight,
  success,
  accent,
  info
};
