export const addErrorInterceptor = (axios, interceptor = () => {}) => {
  axios.interceptors.response.use((response) => response, interceptor);
};

export const addRequestContextInterceptor = (axios) => {
  axios.interceptors.request.use(config => {
    config.headers['href'] = window.location.href;
    return config;
  });
};
