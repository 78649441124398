import { useInputState } from './useInputState';

const validations = [
  {
    validate: value => value !== undefined && value !== null && value !== '',
    errorMessage: 'selection.requiredError'
  }
];

export function useSelectionInputState(options) {
  return useInputState({
    initialValue: '',
    validations,
    ...(options || {})
  });
}
