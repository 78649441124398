import React from 'react';
import PropTypes from 'prop-types';
import Link from 'components/common/Link';
import { getBaseURL } from 'services/baseURL';
import './styles/uploadImageTermsAndConditions.scss';

export default function UploadImageTermsAndConditions({
  localized = {}
}) {
  const baseURL = getBaseURL();
  return (
    <div className='image-preview__t-and-c'>
      <span className='image-preview__t-and-c__content'>{localized['imagePreview.description']}</span>
      {' '}
      <Link
        href={baseURL + localized['imagePreview.termsAndConditions.link']}
        hasEndIcon
        opensInNewTab
        className='image-preview__t-and-c__link'>
        {localized['imagePreview.termsAndConditions']}
      </Link>
    </div>
  );
}

UploadImageTermsAndConditions.propTypes = {
  localized: PropTypes.object
};
