import { useState } from "react";

export const useGiftComplianceError = () => {
  const [relationshipError, setRelationshipError] = useState(null);

  const validateRelationship = (relationship) => {
    return !!relationship;
  };

  const validateRelationshipForm = (relationship) => {
    const isRelationshipValid = validateRelationship(relationship);
    return { isRelationshipValid };
  };

  const updateRelationshipError = (relationship) => {
    if (!validateRelationship(relationship)) {
      setRelationshipError({
        severity: 'error',
        message: 'giftCompliance.error.noRelationship'
      });
    } else {
      setRelationshipError(null);
    }
  };

  return {
    relationshipError,
    validateRelationshipForm,
    updateRelationshipError
  };
};
