import { createTheme } from '@mui/material';
import palette from './palette';
import breakpoints from './breakpoints';
import typography from './typography';

export default createTheme({
  palette,
  breakpoints,
  typography
});
