import { useMemo } from 'react';

export function useSelectedOption({
  options,
  selectedValue
}) {
  return useMemo(() => {
    return (options || []).filter(option => option && option.value === selectedValue)[0];
  }, [options, selectedValue]);
}
