import { useEffect } from 'react';
import ReactGA from 'react-ga4';

export function useAnalyticsOnMount({ category, action, label }) {
  useEffect(() => {
    // Category and Action are required params for event
    category && action && ReactGA.event({
      category,
      action,
      label
    });
  }, []);
}
