import { useMemo } from 'react';
import { useInputState } from './useInputState';
import { validateMissingPassword, checkPasswordStrength } from '../password';

export function usePasswordInputState({ validations } = {}) {
  const passwordValidations = useMemo(() => [
    {
      validate: value => validateMissingPassword(value),
      errorMessage: 'pleaseEnterPassword'
    },
    {
      validate: value => checkPasswordStrength(value),
      errorMessage: 'passwordStrengthError'
    },
    ...(validations || [])
  ], [validations]);

  return useInputState({
    initialValue: '',
    validations: passwordValidations
  });
};
