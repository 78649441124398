import axiosObj from 'axios';
import { addErrorInterceptor, addRequestContextInterceptor } from './interceptors';

export const axios = axiosObj.create({
  baseURL: `${__APIURL__}/v1.0`,
  headers: { common: { sessionid: sessionStorage.getItem('sessionId') } }
});

addRequestContextInterceptor(axios);
addErrorInterceptor(axios);
