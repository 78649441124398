import { injectReducer } from 'store/reducers';
import HomeRoute from './Home';
import LocaleRoute from './Locale';
import GiftRoute from './Gift';

export const createRoutes = (store) => (
  [{
    path: '/',
    getComponent(nextState, cb) {
      require.ensure([], (require) => {
        const CoreLayout = require('components/CoreLayout').default;
        const settingsReducer = require('reducers/settings').default;
        injectReducer(store, { key: 'settings', reducer: settingsReducer });
        cb(null, CoreLayout);
      }, 'coreLayout');
    },
    indexRoute: HomeRoute,
    childRoutes: [
      GiftRoute,
      LocaleRoute
    ]
  }]
);

export default createRoutes;
