import { useInputState } from './useInputState';
import { validateAllowedProvince } from 'services/geo';

const validations = [
  {
    validate: value => value && value.trim && !!value.trim(),
    errorMessage: 'provinceRequiredError'
  },
  {
    validate: value => value ? validateAllowedProvince(value) : true,
    errorMessage: 'provinceNotAllowed'
  }
];

export function useProvinceInputState(options) {
  return useInputState({
    initialValue: '',
    validations,
    ...(options || {})
  });
}
