import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import TextField from 'components/common/TextField';

export default function CurrencyTextField({
  onChange,
  locale,
  value,
  className = '',
  handleClearField,
  disableCurrency = false,
  ...otherProps
}) {
  const localePropsMap = {
    'en-US': {
      ...(!disableCurrency && { prefix: '$' }),
      decimalSeparator: '.',
      thousandSeparator: ','
    },
    'fr-CA': {
      ...(!disableCurrency && { suffix: ' $' }),
      decimalSeparator: ',',
      thousandSeparator: ' '
    }
  };

  function handleChange(values) {
    const { formattedValue, value } = values || {};
    onChange && onChange({
      formattedValue,
      value
    });
  }

  const localeProps = locale && localePropsMap[locale] || localePropsMap['en-US'];
  return (
    <NumberFormat
      customInput={TextField}
      className={className}
      thousandsGroupStyle="thousand"
      isNumericString={typeof value === 'string'}
      {...(localeProps)}
      onValueChange={handleChange}
      allowNegative
      value={value}
      handleClearField={handleClearField}
      {...(otherProps || {})}
    />
  );
}

CurrencyTextField.propTypes = {
  otherProps: PropTypes.object,
  locale: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handleClearField: PropTypes.func,
  disableCurrency: PropTypes.bool,
  onChange: PropTypes.func
};
