import { useInputState } from './useInputState';
import { validatePostalCode, validateCorrespondingPostalCode } from 'services/geo';

const getValidations = (province) => {
  return [
    {
      validate: value => value && value.trim && !!value.trim(),
      errorMessage: 'postalCodeRequiredError'
    },
    {
      validate: value => validatePostalCode(value.trim()),
      errorMessage: 'postalCodeFormatError'
    },
    {
      validate: value => province ? validateCorrespondingPostalCode(province, value) : true,
      errorMessage: 'postalCodeCorrespondError'
    }
  ];
};

export function usePostalCodeInputState(options, province = '') {
  return useInputState({
    initialValue: '',
    validations: getValidations(province),
    ...(options || {})
  });
}
