import giftLinkErrorImg from 'assets/images/gift_link_error.png';

const giftErrorMap = {
  'expired': {
    img: {
      src: giftLinkErrorImg,
      alt: 'img.alt.giftLinkError'
    },
    localizedKey: {
      title: 'giftError.title.expired',
      description: 'giftError.description.expired'
    }
  }
};

export const getGiftError = (reason) => {
  const giftError = giftErrorMap[reason];
  return giftError || giftErrorMap['expired'];
};
