export function formatCurrency(value = '', locale = 'en-US', options) {
  var formatter = new Intl.NumberFormat(locale || 'en-US', {
    style: 'currency',
    currency: 'CAD',
    currencyDisplay: 'narrowSymbol',
    ...(options || {})
  });
  return formatter.format(parseFloat(value));
}

export function formatPercent(value = '', locale = 'en-US', options) {
  var formatter = new Intl.NumberFormat(locale, {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    ...(options || {})
  });
  return formatter.format(parseFloat(value));
}

// Format $1000 as $1k
export function formatCurrencyThousand(value = '', locale = 'en-US', options) {
  return formatCurrency(value, locale, {
    compactDisplay: 'short',
    notation: 'compact',
    unitDisplay: 'narrow',
    ...(options || {})
  });
}

// Round to two decimal places
export function round(num) {
  return (Math.round(Number(num) * 100) / 100).toFixed(2);
}
