import {
  info,
  info100,
  info99,
  info95,
  info90,
  info80,
  info70,
  info60,
  info50,
  info40,
  info30,
  info20,
  info10,
  info0,
  lightInfoContainer
} from 'styles/colors.scss';

export default {
  main: info,
  100: info100,
  99: info99,
  95: info95,
  90: info90,
  80: info80,
  70: info70,
  60: info60,
  50: info50,
  40: info40,
  30: info30,
  20: info20,
  10: info10,
  0: info0,
  lightInfoContainer
};
