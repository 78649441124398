import React from 'react';
import PropTypes from 'prop-types';
import { Chip as MUIChip } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import './styles/chip.scss';

const variantPropMap = {
  'outlined': {
    variant: 'outlined',
    className: 'chip--outlined',
    selectedClassName: 'chip--outlined--selected',
    errorClassName: 'chip--outlined--error'
  }
};

export default function Chip({
  variant = 'outlined',
  className: propClassName,
  onClick,
  disabled,
  selected,
  error,
  hideErrorIcon,
  ...chipProps
}) {
  const MUIChipProps = variant && variantPropMap[variant] || variantPropMap['outlined'];

  const { className, errorClassName, selectedClassName, ...otherMUIChipProps } = MUIChipProps || {};

  return (
    <MUIChip
      {...otherMUIChipProps}
      className={`chip ${className} ${error ? errorClassName : ''} ${selected ? selectedClassName : ''} ${propClassName || ''}`}
      disableRipple
      onClick={onClick}
      onDelete={error ? hideErrorIcon ? null : () => { } : null}
      disabled={disabled}
      deleteIcon={error ? <ErrorIcon className="chips__error-icon" /> : null}
      {...chipProps} />
  );
}

Chip.propTypes = {
  variant: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  selected: PropTypes.bool,
  hideErrorIcon: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
};
