import { logComponentEvent } from "services/logging/componentEvent";

export const useGiftSuccess = () => {
  const onSuccessDoneClick = () => {
    logComponentEvent(
      'GiftingSuccess',
      'DoneClicked',
      { data: { button: 'Done' } }
    );
    window.location.href = 'https://embark.ca';
  };

  return {
    onSuccessDoneClick
  };
};
