import React from 'react';
import ReactDOM from 'react-dom';
import createStore from './store/createStore';
import { Provider } from 'react-redux';
import App from './components/App';
import Promise from 'promise-polyfill';
import '@formatjs/intl-numberformat/polyfill';
import '@formatjs/intl-numberformat/locale-data/en';
import '@formatjs/intl-numberformat/locale-data/fr-CA';
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';

// ========================================================
// Store Instantiation
// ========================================================
const initialState = window.___INITIAL_STATE__;
const store = createStore(initialState);

// To add Promise to window for older browsers
if (!window.Promise) {
  window.Promise = Promise;
}

// object.entries support for older browsers (needed for react-table)
if (!window.Object.entries) {
  window.Object.entries = function(obj) {
    const ownProps = Object.keys(obj);
    let i = ownProps.length;
    const resArray = new Array(i); // preallocate the Array
    while (i--) { resArray[i] = [ownProps[i], obj[ownProps[i]]] }

    return resArray;
  };
}

// Number.isNaN support for older browsers (needed for react-table)
if (!window.Number.isNaN) {
  window.Number.isNaN = function(value) {
    // eslint-disable-next-line no-self-compare
    return value !== value;
  };
}

ReactGA.initialize([{
  trackingId: __GOOGLE_ANALYTICS_ID__,
  debug: __DEV__ || __STAGE__
}]);

ReactGA.set({ anonymizeIp: true, cookieFlags: 'SameSite=None;Secure' });

// ========================================================
// Render Setup
// ========================================================
const MOUNT_NODE = document.getElementById('root');

let render = (Component) => {
  const routes = require('./routes/index').default(store);
  ReactDOM.render(<Provider store={store}><Component routes={routes} /></Provider>, MOUNT_NODE);
};

// ReactGA.initialize('UA-XXXXXXXX-X');

// This code is excluded from production bundle
if (__DEV__) {
  if (module.hot) {
    // Development render functions
    const renderApp = render;
    const renderError = (error) => {
      const RedBox = require('redbox-react').default;
      ReactDOM.render(<RedBox error={error} />, MOUNT_NODE);
    };

    // Wrap render in try/catch
    render = (Component) => {
      try {
        renderApp(Component);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        renderError(error);
      }
    };

    // Setup hot module replacement
    module.hot.accept('./routes/index', () =>
      setImmediate(() => {
        const NextApp = require('./components/App').default;
        ReactDOM.unmountComponentAtNode(MOUNT_NODE);
        render(NextApp);
      })
    );
  }
}

// ========================================================
// Go!
// ========================================================
if (!global.Intl) {
  require.ensure([
    'intl',
    'intl/locale-data/jsonp/en.js'
  ], function (require) {
    require('intl');
    require('intl/locale-data/jsonp/en.js');
    render(App);
  });
} else {
  render(App);
}
