import React from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import IconButton from 'components/common/IconButton';
import {
  ArrowBack as ArrowBackIcon
} from '@mui/icons-material';
import { primary40 } from 'styles/colors.scss';
import './styles/accountToolbar.scss';

export default function AccountToolbar({
  title,
  className,
  onBackClick,
  showBackButton = true
}) {
  return (
    <div className={`account-toolbar ${className || ''}`}>
      <div className="account-toolbar__left">
        <div className="account-toolbar__left__back">
          {showBackButton && (
            <IconButton
              onClick={onBackClick ? () => onBackClick() : () => browserHistory.goBack()}
              data-testid="account-toolbar-back"
              variant="outlined"
            >
              <ArrowBackIcon sx={{ color: primary40 }} />
            </IconButton>
          )}
        </div>
        {(title && (<div className="account-toolbar__left__header">{title}</div>))}
      </div>
    </div>
  );
}

AccountToolbar.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  onBackClick: PropTypes.func,
  showBackButton: PropTypes.bool
};
