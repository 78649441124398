import PropTypes from "prop-types";
import React from "react";
import './styles/iconBanner.scss';

function IconBanner({
  title,
  description,
  className,
  imgSrc,
  imgAlt
}) {
  return (
    <div className={`icon-banner ${className || ''}`}>
      <div className='icon-banner__text'>
        <p className='icon-banner__text__title'>
          {title}
        </p>
        <p className='icon-banner__text__description'>
          {description}
        </p>
      </div>
      <img
        className='icon-banner__img'
        src={imgSrc}
        alt={imgAlt}
      />
    </div>
  );
}

IconBanner.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]),
  className: PropTypes.string,
  description: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]),
  imgSrc: PropTypes.string,
  imgAlt: PropTypes.string
};

export default IconBanner;
