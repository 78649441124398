export default {
  keys: [10, 20, 30, 40],
  values: {
    10: 37.5,
    20: 56.5,
    30: 80,
    40: 90
  },
  unit: 'em'
};
