export function validatePhone(phone = '') {
  const condition = /^([0-9]{10})$/;
  return condition.test(phone);
}

export function formatPhoneNumber(phone = '') {
  if (validatePhone) {
    return `(${phone.slice(0, 3)}) ${phone.slice(3, 6)}-${phone.slice(6)}`;
  }
  return phone;
}
