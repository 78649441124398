import { useInputState } from './useInputState';

const genderOptions = ['male', 'female', 'notProvided'];

const validations = [
  {
    validate: value => value && genderOptions.indexOf(value) !== -1,
    errorMessage: 'selection.requiredError'
  }
];

export function useGenderInputState(options) {
  return useInputState({
    initialValue: '',
    validations,
    ...(options || {})
  });
}
