import { parseISO, format } from 'date-fns';
import { frCA, enUS } from 'date-fns/locale';

const monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
  'July', 'August', 'September', 'October', 'November', 'December'
];

export const dateFormat = 'dd/MM/yyyy';
export const grantDateFormat = 'PPP';

export const localeMap = {
  'en-US': enUS,
  'fr-CA': frCA
};

export function getMonthName(i) {
  // 0 index month
  return monthNames[i];
}

export function convertUTCDateToLocal(date) {
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds());
};

export function convertLocalDateToUTC(date) {
  return new Date(Date.UTC(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    date.getHours(),
    date.getMinutes(),
    date.getSeconds()));
};

export function converToDOBFormat(date) {
  const utcDOB = convertLocalDateToUTC(date);
  utcDOB.setUTCHours(0, 0, 0, 0);

  return utcDOB;
};

export function validateDate(date) {
  if (!date) {
    return false;
  }

  try {
    const newDate = new Date(date);
    newDate.toISOString();
    return true;
  } catch (e) {
    return false;
  }
}

export function getAmPm(hours) {
  return hours >= 12 ? 'pm' : 'am';
}

export function getTime(time, locale) {
  if (!(time && time.getHours && time.getMinutes)) return '';
  const dateFnsLocale = locale && localeMap[locale] || localeMap['en-US'];
  return format(time, 'p', { locale: dateFnsLocale }).replace('AM', 'a.m.').replace('PM', 'p.m.');
}

export function localizeDateTime(isoString, options = {}, locale = 'en-US') {
  const dateTimeOptions = {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    timeZoneName: 'short',
    ...options
  };
  return new Date(isoString).toLocaleTimeString(locale, dateTimeOptions);
};

export function getISODate(date) {
  return date.toISOString().split('T')[0];
};

export function isFutureDate(date) {
  if (!date) {
    return false;
  }

  try {
    const currentDate = new Date().setHours(0, 0, 0, 0);
    const futureDate = date.setHours(0, 0, 0, 0);
    return futureDate >= currentDate;
  } catch (e) {
    return false;
  }
};

export function isMoreThanOneDayAway(date) {
  if (!date) {
    return false;
  }

  try {
    const dateMilliseconds = date.getTime();
    const currentMilliseconds = new Date().getTime();
    return (dateMilliseconds - currentMilliseconds) > 86400000;
  } catch (e) {
    return false;
  }
};

export function getAge(date) {
  if (!date) return 0;

  try {
    const today = new Date();
    const birthDate = new Date(date);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age < 0 || isNaN(age) ? 0 : age;
  } catch (e) {
    return 0;
  }
}

export function parseBroadridgeDateString(dateString) {
  const year = dateString && dateString.slice(0, 4);
  const month = dateString && dateString.slice(4, 6);
  const date = dateString && dateString.slice(6, 8);

  return parseISO(`${year}-${month}-${date}`);
}

export function formatBroadridgeDate(dateObj) {
  const year = dateObj && dateObj.getUTCFullYear();
  const month = dateObj && ('0' + (dateObj.getUTCMonth() + 1)).slice(-2);
  const date = dateObj && ('0' + dateObj.getUTCDate()).slice(-2);

  return `${year}${month}${date}`;
};

export function getAppointmentCalendarStartDate() {
  const currentDay = new Date().getDay();
  let increment;
  switch (currentDay) {
    case 5:
      increment = 3;
      break;
    case 6:
      increment = 2;
      break;
    default:
      increment = 1;
  }
  return new Date(new Date().setDate(new Date().getDate() + increment));
}

export function isValidDate(date) {
  return date instanceof Date && !isNaN(date);
}

export function getCurrentYear() {
  return new Date().getFullYear();
}

export function calculateAge(dob = new Date()) {
  const currentDate = new Date();
  const diffInMs = currentDate.getTime() - dob.getTime();
  const age = new Date(diffInMs);
  return Math.abs(age.getUTCFullYear() - 1970);
}
