import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Dialog from 'components/common/Dialog';
import IconButton from 'components/common/IconButton';
import { Close as CloseIcon } from '@mui/icons-material';
import './styles/reviewDialog.scss';

export default function ReviewDialog({
  isOpen,
  localized,
  onClose
}) {
  return (
    <Dialog
      className='review-dialog'
      renderTitle={() => (
        localized['sendAGift.dialog.title']
      )}
      renderContent={() => (
        <div>
        <IconButton
          size='small'
          variant='filled'
          className={'review-dialog__close-btn'}
          aria-label={localized['close']}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
          <div className='review-dialog__header'>{localized['gifting.review.dialog.header']}</div>
          <div className='review-dialog__body'>{localized['gifting.review.dialog.body']}</div>
        </div>
      )}
      onBackdropClick={onClose}
      isOpen={isOpen}
    />
  );
}

ReviewDialog.propTypes = {
  isOpen: PropTypes.bool,
  localized: PropTypes.object,
  onClose: PropTypes.func
};
