import { useState, useCallback, useEffect } from 'react';
import { getCroppedImg } from 'services/image';
import { useCancelPromise } from 'services/hooks/useCancelPromise';

export default function useImagePreview({
  file,
  fileType,
  setFile,
  setCroppedImage,
  setIsLoading,
  upload,
  onClose,
  beneficiaryAccountId,
  planAccountId
}) {
  const [rotation, setRotation] = useState(360);
  const [zoom, setZoom] = useState(1);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [error, setError] = useState(null);
  const [agreeUploadImage, setAgreeUploadImage] = useState(false);
  const [checkboxError, setCheckboxError] = useState(false);
  const cancelPromise = useCancelPromise();
  const [showRepositionText, setShowRepositionText] = useState(true);

  useEffect(() => {
    const showRepositionTimer = setTimeout(() => {
      setShowRepositionText(false);
    }, 3000);
    return () => clearTimeout(showRepositionTimer);
  }, []);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  function handleCheckboxClick() {
    setAgreeUploadImage(!agreeUploadImage);
    setCheckboxError(false);
  }

  function handleRotation() {
    if (rotation === 360) {
      setRotation(270);
    } else {
      setRotation((value) => value - 90);
    }
  };

  function handleBack() {
    setFile('');
  }

  function onUploadClick() {
    if (!agreeUploadImage) {
      setCheckboxError(true);
    } else {
      setIsLoading(true);
      setError(null);
      getCroppedImg(file, fileType, croppedAreaPixels, rotation)
        .then((croppedImageTypes) => {
          if (!cancelPromise.current) {
            setCroppedImage(croppedImageTypes.base64);
          }
          if (upload) {
            return upload({
              file: croppedImageTypes.blob,
              beneficiaryAccountId,
              planAccountId,
              fileType: croppedImageTypes.blob && croppedImageTypes.blob.type
            });
          } else {
            return Promise.resolve();
          }
        })
        .then((response) => {
          if (!cancelPromise.current) {
            setIsLoading(false);
            setError(null);
            onClose(true);
          }
        })
        .catch(err => {
          if (!cancelPromise.current) {
            setError(err);
            setIsLoading(false);
          }
        });
    }
  }

  return {
    rotation,
    setRotation,
    zoom,
    setZoom,
    crop,
    setCrop,
    showRepositionText,
    croppedAreaPixels,
    setCroppedAreaPixels,
    agreeUploadImage,
    checkboxError,
    handleCheckboxClick,
    error,
    setError,
    onCropComplete,
    handleRotation,
    handleBack,
    onUploadClick
  };
}
