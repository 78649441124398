import { validateDate, isFutureDate } from 'services/dateTime';
import { useInputState } from './useInputState';

const validations = [
  {
    validate: value => !!value,
    errorMessage: 'dateFormatError'
  },
  {
    validate: validateDate,
    errorMessage: 'dateFormatError'
  }
  // {
  //   validate: isFutureDate,
  //   errorMessage: 'futureDateError'
  // }
];

export function useDateInputState(props) {
  const { initialValue, ...useInputStateProps } = props || {};
  return useInputState({
    initialValue: initialValue || null,
    validations,
    ...useInputStateProps
  });
}
