import {
  success,
  success100,
  success99,
  success95,
  success90,
  success80,
  success70,
  success60,
  success50,
  success40,
  success30,
  success20,
  success10,
  success0
} from 'styles/colors.scss';

export default {
  100: success100,
  99: success99,
  95: success95,
  90: success90,
  80: success80,
  70: success70,
  60: success60,
  50: success50,
  40: success40,
  30: success30,
  20: success20,
  10: success10,
  0: success0,
  main: success,
  light: success40,
  dark: success80,
  contrastText: success100
};
