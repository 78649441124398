export class UnsavedChanges {
  static instance;

  static getInstance() {
    if (!UnsavedChanges.instance) {
      UnsavedChanges.instance = new UnsavedChanges();
    }

    return UnsavedChanges.instance;
  }

  unsavedChanges = {};
  checkUnsavedChanges = () => Promise.resolve();

  setUnsavedChange(globalKey, key) {
    this.unsavedChanges = {
      ...this.unsavedChanges,
      [globalKey]: {
        ...(this.unsavedChanges[globalKey] || {}),
        [key]: true
      }
    };
  }

  resetUnsavedChanges(globalKey) {
    delete this.unsavedChanges[globalKey];
  }

  removeUnsavedChange(globalKey, key) {
    if (this.unsavedChanges[globalKey]) {
      if (this.unsavedChanges[globalKey][key]) {
        delete this.unsavedChanges[globalKey][key];
      }

      if (Object.keys(this.unsavedChanges[globalKey]).length === 0) {
        delete this.unsavedChanges[globalKey];
      }
    }
  }

  hasUnsavedChange(globalKey, key) {
    return this.unsavedChanges && this.unsavedChanges[globalKey] && this.unsavedChanges[globalKey][key];
  }

  hasUnsavedChanges(globalKey) {
    return Object.keys(this.unsavedChanges && this.unsavedChanges[globalKey] || {}).length > 0;
  }
}
