import { validateDate } from 'services/dateTime';
import { useInputState } from './useInputState';

const validations = [
  {
    validate: value => !!value,
    errorMessage: 'appointmentDateRequiredError'
  },
  {
    validate: validateDate,
    errorMessage: 'dateFormatError'
  }
];

export function useAppointmentDateInputState(options) {
  return useInputState({
    initialValue: new Date(),
    validations,
    ...(options || {})
  });
}
