import {
  accent,
  accent100,
  accent99,
  accent95,
  accent90,
  accent80,
  accent70,
  accent60,
  accent50,
  accent40,
  accent30,
  accent20,
  accent10,
  accent0
} from 'styles/colors.scss';

export default {
  100: accent100,
  99: accent99,
  95: accent95,
  90: accent90,
  80: accent80,
  70: accent70,
  60: accent60,
  50: accent50,
  40: accent40,
  30: accent30,
  20: accent20,
  10: accent10,
  0: accent0,
  main: accent,
  light: accent90
};
