import { useEffect, useState } from "react";
import { useGiftComplianceError } from "./useGiftComplianceError";
import { browserHistory } from "react-router";
import { logComponentEvent } from "services/logging/componentEvent";

export const useGiftCompliance = () => {
  const { relationshipError, updateRelationshipError, validateRelationshipForm } = useGiftComplianceError();
  const [relationship, setRelationship] = useState('');

  const updateRelationship = (value) => {
    const eventOptions = {
      data: {
        action: value,
        relationship: value
      }
    };

    logComponentEvent(
      'GiftingKYC',
      'RelationshipSelected',
      eventOptions
    );

    updateRelationshipError(value);
    setRelationship(value);
  };

  const onComplianceNext = () => {
    const { isRelationshipValid } = validateRelationshipForm(relationship);
    logComponentEvent(
      'GiftingKYC',
      'ContinueClicked',
      { data: { button: 'Continue' } }
    );

    if (isRelationshipValid) {
      browserHistory.push('/gift/review');
    } else {
      updateRelationshipError(relationship);
    }
  };

  return {
    relationship,
    relationshipError,
    updateRelationship,
    onComplianceNext,
    validateRelationshipForm
  };
};
