import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import { LoadingButton as MUIButton } from '@mui/lab';
import './styles/button.scss';

const variantPropMap = {
  'filled': {
    variant: 'contained',
    className: 'button--filled',
    isLoadingClassName: 'button--filled--loading'
  },
  'outlined': {
    variant: 'outlined',
    className: 'button--outlined',
    isLoadingClassName: 'button--outlined--loading'
  },
  'text': {
    variant: 'text',
    className: 'button--text',
    isLoadingClassName: 'button--text--loading'
  },
  'elevated': {
    variant: 'contained',
    className: 'button--elevated',
    isLoadingClassName: 'button--elevated--loading'
  },
  'tonal': {
    variant: 'contained',
    className: 'button--tonal',
    isLoadingClassName: 'button--tonal--loading'
  },
  'navigation': {
    variant: 'contained',
    className: 'button--navigation',
    isLoadingClassName: 'button--navigation--loading'
  }
};

const sizeMap = {
  'small': {
    className: 'button--small'
  },
  'medium': {
    className: 'button--medium'
  },
  'large': {
    className: 'button--large'
  }
};

export default function Button({
  setHideHeader,
  children,
  variant = 'filled',
  className: propClassName,
  href,
  Img,
  SecondaryImg,
  isExternalLink = false,
  onClick,
  isLoading,
  isActive,
  buttonRef,
  disabled,
  size = 'medium',
  prevPath,
  endIcon,
  ...buttonProps
}) {
  const MUIButtonProps = variant && variantPropMap[variant] || variantPropMap['filled'];
  const MUIButtonSizeProps = size && sizeMap[size] || sizeMap['medium'];
  const { className, isLoadingClassName, ...otherMUIButtonProps } = MUIButtonProps || {};
  const { className: sizeClassName } = MUIButtonSizeProps || {};

  function handleButtonClick(e) {
    if (!isExternalLink) {
      if (href) {
        e.preventDefault && e.preventDefault();
        browserHistory.push({ pathname: href, state: { prevPath } });
        setHideHeader && setHideHeader(false);
      }
      onClick && onClick(e);
    }
  }
  return (
    <MUIButton
      {...otherMUIButtonProps}
      className={`button ${className} ${sizeClassName || ''} ${isLoading ? isLoadingClassName : ''} ${propClassName || ''}`}
      onClick={handleButtonClick}
      href={href}
      ref={buttonRef}
      disableFocusRipple
      aria-busy={isLoading}
      disabled={disabled}
      loading={isLoading}
      startIcon={Img && <Fragment>
        <img className='mainIcon' src={Img} alt="mainIcon" />
        <img className='secondaryIcon' src={SecondaryImg} alt="secondaryIcon" />
      </Fragment>}
      endIcon={endIcon && <img className='endIcon' src={endIcon} alt="endIcon" />}
      size={size}
      {...buttonProps}>
      {children}
    </MUIButton>
  );
}

Button.propTypes = {
  setHideHeader: PropTypes.func,
  children: PropTypes.node,
  variant: PropTypes.string,
  className: PropTypes.string,
  Img: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ]),
  SecondaryImg: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ]),
  href: PropTypes.string,
  size: PropTypes.string,
  isExternalLink: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  isActive: PropTypes.bool,
  prevPath: PropTypes.string,
  endIcon: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ]),
  buttonRef: PropTypes.object
};
