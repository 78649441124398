import { useInputState } from './useInputState';
import { validateNameFormat, validateMiddleNameLength } from '../name';

const validations = [
  {
    validate: value => validateNameFormat(value),
    errorMessage: 'invalidMiddleName'
  },
  {
    validate: value => validateMiddleNameLength(value),
    errorMessage: 'invalidMiddleNameLength'
  }
];

export function useMiddleNameInputState(options) {
  return useInputState({
    initialValue: '',
    validations,
    ...(options || {})
  });
}
