import { useRef, useEffect } from "react";
import { getSlugByPathName } from "services/route";
import { browserHistory } from "react-router";

/**
 * @description Construct path object saved into history
 * @param location react-router's location object, on Redux State
 * @returns {Object}
*/

const constructPath = (location) => {
  return {
    key: location.key,
    path: getSlugByPathName(location.pathname)
  };
};

/**
 * @description Save user's history index to state by path segment, used for tracking user back and forward history to state
 * @param location react-router's location object, on Redux State
 * @returns {Object}
*/

export const useHistoryIndex = (initialLocation) => {
  const historyRef = useRef([constructPath(initialLocation)]);
  const historyPositionRef = useRef(0);

  // Replace or push new path to history and increment position
  const replaceHistory = (location) => {
    const newPath = constructPath(location);
    const newIndex = historyPositionRef.current + 1;
    const previousHistory = historyRef.current.slice(0, newIndex);

    // Replace state with new path
    historyPositionRef.current = newIndex;
    historyRef.current = [...previousHistory, newPath];
  };

  // Append new path to front of history without adjusting position
  const unshiftHistory = (location) => {
    const newPath = constructPath(location);
    historyRef.current = [newPath, ...historyRef.current];
  };

  // Replace full history with new location
  const replaceFullHistory = (location) => {
    const newPath = constructPath(location);
    historyPositionRef.current = 0;
    historyRef.current = [newPath];
  };

  useEffect(() => {
    // Note: Use returned location from browserHistory.listen to avoid stale location object
    const backListener = browserHistory.listen((location) => {
      const { key } = location;

      if (location.action === 'POP') {
        // If user pathed 'forward' through browser
        if (historyRef.current[historyPositionRef.current + 1]?.key === key) {
          historyPositionRef.current = historyPositionRef.current + 1;

        // If user pathed 'back' through browser
        } else if (historyRef.current[historyPositionRef.current - 1]?.key === key) {
          historyPositionRef.current = historyPositionRef.current - 1;

        // If user has moved 'back' from a location that does not exist in state
        } else if (historyRef.current.length > 1 && historyPositionRef.current <= 0) {
          unshiftHistory(location);

        // If user has redirected without any context
        // Note: Trigger's when user refreshes page then moves forward or back, without any history in state.
        } else {
          replaceFullHistory(location);
        }
      }

      // If user has triggered browserHistory.push(), add to history
      if (location.action === 'PUSH') {
        replaceHistory(location);
      }
    });

    return () => backListener();
  }, []);

  return {
    historyRef,
    historyPositionRef
  };
};
