import React from 'react';
import PropTypes from 'prop-types';
import Cropper from 'react-easy-crop';
import Slider from '@mui/material/Slider';
import SmallerPreview from 'assets/icons/smaller_preview.svg';
import LargerPreview from 'assets/icons/larger_preview.svg';
import Rotate90Degrees from 'assets/icons/rotate_90_degrees.svg';
import DragIcon from 'assets/icons/drag_icon.svg';
import Button from 'components/common/Button';
import useImagePreview from './hooks/useImagePreview';
import ImageUploading from '../ImageUploading';
import UploadImageTermsAndConditions from './components/UploadImageTermsAndConditions';
import Alert from 'components/common/Alert';
import { parseErrorMessage } from 'services/webApi';
import Checkbox from 'components/common/Checkbox';
import './styles/imagePreview.scss';

export default function ImagePreview({
  localized,
  file, // base64 image
  fileType,
  locale,
  setFile,
  isLoading,
  setIsLoading,
  croppedImage,
  setCroppedImage,
  upload,
  onClose,
  beneficiaryAccountId,
  planAccountId
}) {
  const {
    rotation, zoom, setZoom, crop, setCrop, showRepositionText, error, onCropComplete,
    handleRotation, agreeUploadImage, checkboxError, handleCheckboxClick, handleBack, onUploadClick
  } = useImagePreview({
    file,
    setFile,
    setCroppedImage,
    fileType,
    upload,
    setIsLoading,
    onClose,
    beneficiaryAccountId,
    planAccountId
  });

  if (croppedImage && isLoading) {
    return (
      <ImageUploading
        croppedImage={croppedImage}
        localized={localized}
      />
    );
  } else {
    return (
      <div className='image-preview'>
        <div className='image-preview__headline'>{localized['imagePreview.headline']}</div>
        <div className="image-preview__header">
          {(error || checkboxError) && (
            <Alert
              severity='error'
              showBorder
              className='image-preview__header__error'>
              {error ? parseErrorMessage(error, locale) : localized['termsConditions.disagreeError']}
            </Alert>)}
        </div>
        <div className='crop-container'>
          {showRepositionText && <div className='crop-container__reposition'>
            <img src={DragIcon} />
            <span>{localized['imagePreview.dragToReposition']}</span>
          </div>}
          <Cropper
            image={file}
            crop={crop}
            zoom={zoom}
            rotation={rotation}
            showGrid={false}
            cropShape="round"
            aspect={1}
            objectFit='horizontal-cover'
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          />
        </div>
        <div className='image-preview__controls'>
          <img className='image-preview__controls__left' src={SmallerPreview} alt={localized['imagePreview.zoomOutPreview']} />
          <div className='image-preview__controls__slider'>
            <Slider
              value={zoom}
              min={1}
              max={3}
              step={0.1}
              aria-label="Zoom"
              onChange={(e, value) => setZoom(value)} />
          </div>
          <img className='image-preview__controls__right' src={LargerPreview} alt={localized['imagePreview.zoomInPreview']} />
        </div>
        <div className='image-preview__rotate'>
          <div className='image-preview__rotate__btn' type="button" onClick={handleRotation}>
            <div className='image-preview__rotate__btn__icon'><img src={Rotate90Degrees} alt="" /></div>
            <div className='image-preview__rotate__btn__text'>{localized['imagePreview.rotate']}</div>
          </div>
        </div>
        <div className='image-preview__description'>
          <Checkbox
            className="image-preview__description__checkbox"
            onChange={handleCheckboxClick}
            error={checkboxError}
            checked={agreeUploadImage}
            label={<UploadImageTermsAndConditions localized={localized} />}
          />
        </div>
        <div className="image-preview__buttons">
          <div className="image-preview__buttons__button">
            <Button size="medium" type='submit' variant='tonal' onClick={onUploadClick}>
              {localized['save']}
            </Button>
          </div>
          <div className="image-preview__buttons__button">
            <Button size="medium" variant='text' onClick={handleBack}>
              {localized['back']}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

ImagePreview.propTypes = {
  localized: PropTypes.object,
  locale: PropTypes.string,
  file: PropTypes.string,
  fileType: PropTypes.string,
  setFile: PropTypes.func,
  isLoading: PropTypes.bool,
  setIsLoading: PropTypes.func,
  croppedImage: PropTypes.string,
  setCroppedImage: PropTypes.func,
  upload: PropTypes.func,
  onClose: PropTypes.func,
  beneficiaryAccountId: PropTypes.string,
  planAccountId: PropTypes.string
};
