// example category objects and definitions
export const EVENTCAT_GIFTING = {
  category: 'Gifting',
  label: 'Gifting',
  eventprefix: 'GIFT',
  paths: ['/gift']
};
const eventCategoryDefinations =
[
  EVENTCAT_GIFTING
];

export function getEventCategoryFromLocation() {
  const eventCategory = eventCategoryDefinations?.filter((categoryDefination) =>
    categoryDefination.paths?.filter((path) =>
      window.location.href.includes(path)).length > 0)[0];
  if (!eventCategory) {
    return { label:'--', category:'--', eventprefix:'' };
  } else {
    const { label, category, eventprefix } = eventCategory;
    return { label, category, eventprefix };
  }
}
