import React, { useState } from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import { FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import './styles/dropdown.scss';

const variantPropMap = {
  'tonal': {
    variant: 'filled',
    className: 'dropdown--tonal'
  }
};

export default function Dropdown({
  items,
  label = '',
  variant,
  id = '',
  onSelect,
  value,
  helperText = '',
  disabled,
  inputProps,
  className: propClassName,
  error,
  showErrorMessage = true,
  ...props
}) {
  const [fieldId, setFieldId] = useState(shortid.generate() || '');
  const MUIDropdownProps = variant && variantPropMap[variant];
  const { variant: dropdownVariant, className } = MUIDropdownProps || {};

  return (
    <FormControl
      variant={dropdownVariant || 'outlined'}
      fullWidth className={`dropdown ${className || ''} ${propClassName || ''}`} error={!!error}>
      {label && (
        <InputLabel>
          {label}
        </InputLabel>
      )}
      <Select
        IconComponent={KeyboardArrowDownIcon}
        className={`dropdown__menu`}
        id={id || fieldId}
        label={label}
        onChange={onSelect}
        disabled={disabled}
        value={value}
        MenuProps={{
          className: 'dropdown__menu',
          transitionDuration: 0,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left'
          }
        }}
        inputProps={inputProps}
        {...(props || {})}
      >
        <MenuItem value={null} className='dropdown__item__hidden' />
        {items && items.map((item, index) =>
          <MenuItem
            className='dropdown__item'
            key={(id || fieldId) + (item.value || index)}
            value={item.value}
            disableRipple
            disableGutters
          >
            {item.label}
          </MenuItem>
        )}
      </Select>
      {showErrorMessage && error && (
        <FormHelperText>{error}</FormHelperText>
      )}
      {helperText && !error && (
        <FormHelperText>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
}

Dropdown.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  helperText: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object),
  onSelect: PropTypes.func,
  disabled: PropTypes.bool,
  inputProps: PropTypes.object,
  className: PropTypes.string,
  error: PropTypes.string,
  variant: PropTypes.string,
  showErrorMessage: PropTypes.bool
};
