import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import Avatar from '@mui/material/Avatar';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import IconButton from 'components/common/IconButton';
import ImageUploadDialog from './components/ImageUploadDialog';
import { getInitials } from 'services/name';
import './styles/profileImage.scss';

export default function ProfileImage({
  locale,
  localized,
  name,
  upload,
  removePicture,
  profileImageSrc,
  isUploadPictureEnabled = true,
  beneficiaryAccountId,
  planAccountId,
  profilePictureBlobId
}) {
  const { first, last } = name || {};
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  function handleDialogClose() {
    setIsDialogOpen(false);
    setIsLoading(false);
  }

  function handleDialogOpen() {
    setIsDialogOpen(true);
  };

  return (
    <div className="profile-image">
      {profileImageSrc
      ? (
        <Avatar
          alt={getInitials(first, last)}
          src={profileImageSrc}
          sx={{ width: 126, height: 126 }}
        />
      ) : (
        <Avatar>
          {getInitials(first, last) || (<PermIdentityIcon />)}
        </Avatar>
      )}
      {isUploadPictureEnabled && (
        <Fragment>
          <div className="profile-image__upload-btn">
            <IconButton
              variant='avatar'
              aria-label={localized['profileImage.ariaLabel']}
              onClick={handleDialogOpen}
            >
              <PhotoCameraIcon />
            </IconButton>
          </div>
          <ImageUploadDialog
            name={name}
            localized={localized}
            locale={locale}
            isOpen={isDialogOpen}
            onClose={() => handleDialogClose()}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            upload={upload}
            removePicture={removePicture}
            profileImageSrc={profileImageSrc}
            beneficiaryAccountId={beneficiaryAccountId}
            planAccountId={planAccountId}
            profilePictureBlobId={profilePictureBlobId}
          />
        </Fragment>
      )}
    </div>
  );
}

ProfileImage.propTypes = {
  locale: PropTypes.string,
  localized: PropTypes.object,
  name: PropTypes.shape({
    first: PropTypes.string,
    last: PropTypes.string
  }),
  upload: PropTypes.func,
  removePicture: PropTypes.func,
  profileImageSrc: PropTypes.string,
  isUploadPictureEnabled: PropTypes.bool,
  beneficiaryAccountId: PropTypes.string,
  planAccountId: PropTypes.string,
  profilePictureBlobId: PropTypes.string
};
