import { useInputState } from './useInputState';

const validations = [
  {
    validate: value => value && !!value,
    errorMessage: 'dependentsRequiredError'
  }
];

export function useDependentsInputState(options) {
  return useInputState({
    initialValue: '',
    validations,
    ...(options || {})
  });
}
