import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import {
  TextField as MUITextField,
  InputAdornment
} from '@mui/material';
import IconButton from 'components/common/IconButton';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import ErrorIcon from '@mui/icons-material/Error';
import ClearIcon from '@mui/icons-material/Clear';
import CancelIcon from '@mui/icons-material/Cancel';
import { LocalizationContext } from 'services/localization';
import './styles/textField.scss';

export default function TextField({
  id,
  type = 'text',
  variant = 'outlined',
  className = '',
  helperText = '',
  uppercase,
  isInErrorState = false,
  showErrorMessage = true,
  error,
  endIcon = '',
  handleClearField,
  clearIconVariant = 'clear',
  isClearIconVisible = true,
  readOnly = false,
  textFieldColor,
  InputProps,
  ...otherProps
}) {
  const [fieldId, setFieldId] = useState(shortid.generate() || '');
  const [showPassword, setShowPassword] = useState(false);
  const localized = useContext(LocalizationContext);
  let inputClassName = 'text-field ' + className;

  if (type === 'password' || error) {
    inputClassName += ' text-field--with-adornment';
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleIconClick = () => {
    setShowPassword(!showPassword);
  };

  function getAdornmentIcon() {
    if (type === 'password') {
      return (
        <InputAdornment position="end">
          <IconButton
            title={localized['textfield.passwordVisibility']}
            onClick={() => handleIconClick()}
            onMouseDown={handleMouseDownPassword}
          >
            {showPassword
            ? (
              <VisibilityOutlinedIcon
                className='text-field__visibility-icon'
                aria-label={localized['textfield.showPassword']}
              />
            ) : (
              <VisibilityOffOutlinedIcon
                className='text-field__visibility-icon'
                aria-label={localized['textfield.hidePassword']}
              />
            )}
          </IconButton>
        </InputAdornment>
      );
    } else if (type === 'text' && handleClearField && isClearIconVisible) {
      return (
        <InputAdornment position="end">
          <IconButton onClick={() => handleClearField()}>
            {clearIconVariant === 'clear' && (
              <ClearIcon
                className='text-field__clear-icon'
                aria-label={localized['textfield.clearInput']}
              />
            )}
            {clearIconVariant === 'filled' && (
              <CancelIcon fontSize="medium"
                className='text-field__clear-icon'
                aria-label={localized['textfield.cancelInput']}
              />
            )}
          </IconButton>
        </InputAdornment>
      );
    } else if (endIcon) {
      return (
        <InputAdornment position="end">
          <IconButton disabled>
            <img src={endIcon} alt="icon field" />
          </IconButton>
        </InputAdornment>
      );
    }
  }

  return (
    <MUITextField
      className={inputClassName}
      variant={variant}
      InputLabelProps={uppercase && { style: { textTransform: 'uppercase' } }}
      id={id || fieldId}
      error={isInErrorState || !!error}
      helperText={showErrorMessage && error ? error : helperText}
      {...(textFieldColor && { color: textFieldColor })}
      type={(type === 'password' && !showPassword ? 'password' : 'text') || type}
      InputProps={{
        readOnly,
        ...(type === 'password' || error || handleClearField || endIcon) && {
          endAdornment: error
            ? (
              <InputAdornment position="end">
                <IconButton
                  title={error ? localized['textfield.inputError'] : type === 'password' ? localized['textfield.passwordVisibility'] : ''}
                  disabled={!!error}
                  {...(!!error && { 'aria-hidden': true })}
                >
                  <ErrorIcon
                    className='text-field__error-icon'
                    aria-label={localized['textfield.inputError']}
                  />
                </IconButton>
              </InputAdornment>
            ) : getAdornmentIcon()
        },
        ...(InputProps || {})
      }}
      {...(otherProps || {})}
    />
  );
}

TextField.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  variant: PropTypes.string,
  helperText: PropTypes.string,
  uppercase: PropTypes.bool,
  isInErrorState: PropTypes.bool,
  readOnly: PropTypes.bool,
  id: PropTypes.string,
  textFieldColor: PropTypes.string,
  endIcon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  error: PropTypes.string,
  InputProps: PropTypes.object,
  showErrorMessage: PropTypes.bool,
  handleClearField: PropTypes.func,
  clearIconVariant: PropTypes.oneOf(['clear', 'filled']),
  isClearIconVisible: PropTypes.bool
};
