import { connect } from 'react-redux';
import Home from './Home';
import { loadLocale } from 'reducers/locale';

const mapDispatchToProps = {
  loadLocale
};

const mapStateToProps = ({ locale, settings }) => ({
  locale, settings
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
