import React, { useState } from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import { FormControlLabel, Checkbox as MUICheckbox } from '@mui/material';
import './styles/checkbox.scss';

export default function Checkbox({
  id,
  label,
  checked,
  onChange,
  disabled,
  className,
  error,
  ...checkboxProps
}) {
  const [fieldId] = useState(shortid.generate());
  function getCheckboxClassname() {
    if (disabled) return 'checkbox--disabled';
    let checkboxClassname = '';
    if (checked) {
      checkboxClassname += ' checkbox--checked';
    }
    if (error) {
      checkboxClassname += ' checkbox--error';
    }
    return checkboxClassname;
  }
  return (
    <FormControlLabel
      className={`checkbox ${className || ''}`}
      label={label}
      control={
        <MUICheckbox
          className={'checkbox ' + getCheckboxClassname()}
          checked={checked}
          onChange={onChange}
          disabled={disabled}
          id={id || fieldId}
          {...(checkboxProps || {})}
        />
      }
    />
  );
};

Checkbox.propTypes = {
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  className: PropTypes.string
};
