import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import giftImage from 'assets/images/gift_image.svg';
import Button from 'components/common/Button';
import bgImage1 from 'assets/images/bg-image1.svg';
import bgImage2 from 'assets/images/bg-image2.svg';
import { logComponentEvent } from 'services/logging/componentEvent';
import './styles/giftSuccess.scss';

export default function GiftSuccess({ localized, onSuccessDoneClick }) {
  useEffect(() => {
    logComponentEvent('GiftingSuccess', 'PageLoaded');
  }, []);

  return (
    <div className='gift-success'>
      <img src={bgImage1} alt='square' className='gift-success__bg1' />
      <img src={bgImage2} alt='semi-annulus' className='gift-success__bg2'/>
      <img
        className='gift-success__hero-img'
        alt='gift-image'
        src={giftImage}
      />
      <h1 className='gift-success__heading'>
        {localized['gifting.success.header'] || ''}
      </h1>
      <p className='gift-success__message'>
        {localized['gifting.success.body'] || ''}
      </p>
      <Button
        onClick={onSuccessDoneClick}
        className='gift-success__button'
        variant='tonal'
      >
        {localized['gifting.success.button'] || ''}
      </Button>
    </div>
  );
}

GiftSuccess.propTypes = {
  localized: PropTypes.object,
  onSuccessDoneClick: PropTypes.func
};
