import {
  primary,
  primary100,
  primary99,
  primary95,
  primary90,
  primary80,
  primary70,
  primary60,
  primary50,
  primary40,
  primary30,
  primary20,
  primary10,
  primary0
} from 'styles/colors.scss';

export default {
  100: primary100,
  99: primary99,
  95: primary95,
  90: primary90,
  80: primary80,
  70: primary70,
  60: primary60,
  50: primary50,
  40: primary40,
  30: primary30,
  20: primary20,
  10: primary10,
  0: primary0,
  main: primary,
  light: primary40,
  dark: primary80,
  contrastText: primary100
};
