import { useState } from 'react';
import { useDropzone } from 'react-dropzone';

export default function useImageDrop({ setFile }) {
  const [alertProps, setAlertProps] = useState(null);

  const { fileRejections, getRootProps, getInputProps, open } = useDropzone({
    accept: { 'image/*': ['.png', '.gif', '.jpeg', '.jpg'] },
    multiple: false,
    maxSize: 2.5 * 1024 * 1024, // 2.5mb
    noClick: true,
    onDropAccepted: handleDropAccepted,
    onDropRejected: handleDropRejected,
    validator: validateFile
  });

  function validateFile(file) {
    if (file && file.type && file.type === 'image/heic') {
      return {
        code: 'file-invalid-type',
        message: 'imageError.invalidFileType'
      };
    }
  }

  function handleDropAccepted(acceptedFiles) {
    setAlertProps(null);
    setFile(acceptedFiles[0]);
  }

  function handleDropRejected(files) {
    files.map(({ file, errors }) => {
      if (errors[0].code === 'file-too-large') {
        setAlertProps({
          severity: 'error',
          message:'imageError.fileTooLarge'
        });
      } else if (errors[0].code === 'file-invalid-type') {
        setAlertProps({
          severity: 'error',
          message:'imageError.invalidFileType'
        });
      } else {
        setAlertProps({
          severity: 'error',
          message:'imageError.otherImageError'
        });
      }
    });
  }

  return {
    alertProps,
    getRootProps,
    getInputProps,
    open
  };
}
