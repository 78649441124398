import { useInputState } from './useInputState';

const validations = [
  {
    validate: value => typeof value === 'boolean',
    errorMessage: 'selection.requiredError'
  }
];

export function useBooleanInputState(options) {
  return useInputState({
    initialValue: null,
    validations,
    ...(options || {})
  });
}
